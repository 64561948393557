import { Component, OnInit, Input, HostListener, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { PageService } from '../../services/page.service';
import { Menu } from 'primeng/menu';
import { DialogComponent } from '../dialog/dialog.component';
import { ApiResponse, HttpService } from '../../services/http.service';
import { Subscription } from 'rxjs';
import { EventService } from '../../services/event.service';
import { DeceaseService } from 'src/app/logic/shared/services/decease.service';
import { FileUpload } from 'src/app/logic/shared/models/manageentityrequest';
import { Deceased } from 'src/app/logic/shared/models/deceasedrequestresponse';
import { ConfigService } from '../../../logic/shared/services/config.service';
import { CacheService } from '../../services/cache.service';
import { environment } from '../../../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-masterview',
  templateUrl: './master-view.component.html',
  styleUrls: ['./master-view.component.scss'],
  providers: [DeceaseService]
})
export class MasterViewComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmationdg', { static: false }) confirmationdg: DialogComponent;
  @ViewChild('dialogue', { static: false }) dialogue: DialogComponent;

  @ViewChild('walkthroughdg', { static: false }) walkthroughdg: DialogComponent;
  deceased: Deceased.Request = {} as Deceased.Request;
  @Input() navItems: any[] = [];

  @Output() onNavSelected = new EventEmitter<any>();

  @ViewChild('menu') menu: Menu;

  eventServiceSubscription: Subscription;
  data: ApiResponse;
  isbtnvisible: boolean = true
  s3iconcolor: string = "#FFFFFF";
  fileuploadres: FileUpload.Response[] = [];
  isfileUpload: boolean = false;
  @Input() ismainadmin: boolean = false;
  visible: boolean = true
  checked: any
  isAdminPortal: boolean;

  constructor(private httpservice: HttpService, public pageservice: PageService, private eventService: EventService, private decease: DeceaseService, private cacheServ: CacheService) {
    this.eventServiceSubscription = this.eventService.observable().subscribe(data => {
      if (data.type == "Upload_File_Proggress") {
        let tIndex = this.fileuploadres.findIndex(tdata => {
          return tdata.Key == data.data.Key;
        });

        if (tIndex == -1) {
          this.fileuploadres.push(data.data);
        }
        else {
          this.fileuploadres[tIndex].Percantage = data.data.Percantage;
          this.fileuploadres[tIndex].Load = data.data.Load;
          this.fileuploadres[tIndex].id = data.data.id;
        }
      }
    });
    this.isAdminPortal = environment.isAdminUI;
  }

  isWKDgLoading() {
    if (this.isview) {
      return true
    } else {
      return false
    }
  }

  isview: boolean = false;
  videoUrl: any;

  onWalkThrougthVideo() {
    this.isview = true
    this.walkthroughdg.open();
    setTimeout(() => {
      this.videoUrl = this.pageservice.getWalkThroughUrl();
      this.isview = false
    }, 1200);
    // let tdata = ConfigService.AppConfig.Settings.WalkThrough.filter(res => {
    //   return res.moduleid == moduleid
    // })
  }
  oncloseddg(ev) {
    this.videoUrl = "";
    var myVideo: any = document.getElementById("video");
    myVideo.pause();
  }

  getWalkThroughUrl() {
    return this.videoUrl
  }
  getZindex() {
    return this.pageservice.getModuleId() == 13;
  }
  removeFile(item: FileUpload.Response) {
    let fileUploadIndex = this.fileuploadres.findIndex(data => {
      return data.Key == item.Key
    });
    this.fileuploadres.splice(fileUploadIndex, 1);
  }
  onStartTransfer() {
    this.fileuploadres = []
    this.deceased = {} as Deceased.Request;
    this.dialogue.open();
  }

  OnPicUpload(ev: any) {
    console.log(ev.Location);
    this.deceased.deathCertificate = ev.Location

    // this.entity[0].picturename = ev.orignalFileName
    // this.entity.picture = ev.Location;
  }

  Save() {
    if (this.deceased.deathCertificate) {
      this.isfileUpload = true;
      this.decease.StartTransfer(this.deceased).then(res => {
        this.data = res;
        this.pageservice.showSuccessMsg(res.Message);
      }).catch(err => {
        this.data = err;
        this.isfileUpload = false;
        this.pageservice.showErrorMsg(err.Message);
      }).finally(() => {
        this.isfileUpload = false;
      })
    }
    else {
      this.pageservice.showErrorMsg("Upload file");
    }

  }
  ngOnDestroy() {
    this.eventServiceSubscription.unsubscribe();
  }

  isDgLoading() {
    return this.data == undefined;
  }
  isNavSelected(item: any) {
    //console.log("************ Selected NAV ", this.pageservice.navservice.selectedNav);
    // if (this.pageservice.navservice.selectedNav == item.route) {
    //   return true
    // }
    // else {
    return this.pageservice.navservice.selectedNav == item.route;
    // }
  }

  onWheel(event: WheelEvent): void {
    (<Element>event.target).parentElement.scrollTop += event.deltaY;
    event.preventDefault();
  }
  isNavVisible(item: any) {
    return true;
  }

  routeSelection(input: any) {
    if (this.pageservice.isPageFree() && this.pageservice.isPageReadyForRedirect()) {
      this.onNavSelected.emit(input.route);
      this.pageservice.navservice.selectedNav = input.value;
      this.setRouteModule(input.route)
      this.pageservice.setWalkThroughUrl(input.walkthrough);
      this.pageservice.setWalkThroughButtonShow(true);
    }
  }

  setRouteModule(route: any) {
    let navItem = this.pageservice.loginNavItems.find(data => {
      return data.route == route || data.route == route + "/list";
    })
    if (navItem) {
      if (navItem.value != 7) {
        const groups = ConfigService.AppConfig.Settings.VirtualVaultGroups
        const folders = ConfigService.AppConfig.Settings.VirtualVaultFolders;
        this.cacheServ.setCacheData("Virtual_Valut_Selected_Group", groups[0]);
        this.cacheServ.setCacheData("Virtual_Valut_Selected_Folder", folders[0]);
      }
      this.eventService.publish2("MODULE_ID", navItem.value);
      this.pageservice.setModuleId(navItem.value);
      this.pageservice.setModuleName(navItem.title);
      this.pageservice.setTwoFARequired(navItem.twofa);
      this.pageservice.setDashboardStyle(navItem.dashboardstyle);
    } else {
      this.pageservice.setModuleId(0);
      this.pageservice.setModuleName("");
      this.pageservice.setDashboardStyle("");
      this.pageservice.setTwoFARequired(false);
    }


    this.pageservice.isRestrictedModule = false;
    if (this.pageservice.userPurchasesData && this.pageservice.userPurchasesData.length > 0) {
      let defaultSubscriptionInTrial = this.pageservice.userPurchasesData.find(data => { return data.subscription?.isdefault }).istrial;
      if (defaultSubscriptionInTrial) {
        let moduleID = navItem.value;
        let restrictedModule = this.pageservice.restrictedModules.find(data => { return data.moduleid == moduleID });
        if (restrictedModule) {
          this.pageservice.selectedRestrictedModule = restrictedModule;
          this.pageservice.isRestrictedModule = true;
        }
      }
    }
  }

  ngOnInit() {
    //$("#mainnav_dom").addClass("ui-sidebar-active");
    // this.openMainNav();
    this.pageservice.resetPageLayout();

  }

  SignOut() {
    this.logout();
    return;
  }

  logout() {
    if (this.pageservice.isPageFree() && this.pageservice.isPageReadyForRedirect()) {
      this.pageservice.logout();
      this.pageservice.route("/auth");
    }
  }
  ngAfterViewInit(): void {
    this.pageservice.setMenu(this.menu);
    this.pageservice.setConfirmationDg(this.confirmationdg);

    if (this.pageservice.checkMenuActive()) {
      $('#maincontentdiv_dom').css({ paddingLeft: "300px" });
    }
    else {
      $('#maincontentdiv_dom').css({ paddingLeft: "0px" });
    }
    // if (!this.pageservice.GetUserPurchases()) {
    //   this.pageservice.hideLeftNavBar();
    // }

    setTimeout(() => {
      this.showMintanceMode()
    }, 2000);
  }

  showMintanceMode() {
    if (ConfigService.notLive == 1) {
      this.dgopen(ConfigService.notLiveMessage, undefined)
      return true
    }
    else if (ConfigService.notLive == 2) {
      this.dgopen(ConfigService.notLiveMessage, [{ title: "Refresh" }])
      return true;
    }
  }


  dgopen(res, options: any) {
    return new Promise((success, failure) => {
      this.pageservice.Confirm(res, options).then(x => {
        let res: any = x;
        if (res.title == "Refresh") {
          document.location.reload();
        }
        else {
          success(undefined);
        }
      }).finally(() => {
      })
    })
  }


  isLogin() {
    return this.pageservice.isLogin();
  }
  getLoginText() {
    return this.isLogin() ? "Signed-in As" : "Hello!";
  }

  getLoginName() {
    return this.isLogin() ? this.pageservice.getLoginName() : "Guest User";
  }

  getDisplayLoginNames() {
    return this.pageservice.getLoginName();
  }

  getLoginUserImage() {
    return this.pageservice.getDisplayLoginImage() ? this.pageservice.getDisplayLoginImage() : '/assets/images/profile-icon.png';
  }

  getLoginUserAge() {
    return this.pageservice.getDisplayLoginAge();
  }
  getListOfUserProfile() {
    if (this.pageservice.isLogin())
      return this.pageservice.getListOfProfile();
  }

  onProfile() {
    this.pageservice.route("profile");
  }

  getLoginUsergGender() {
    return this.pageservice.getDisplayLoginGender();
  }

  showLoader() {
    return !this.pageLoading() && !this.subPageLoading() && this.isPageBusy();
  }

  pageLoading() {
    return !this.pageservice.isPageReady() && !this.pageservice.isSubPage;
  }


  subPageLoading() {
    return !this.pageservice.isPageReady() && this.pageservice.isSubPage;
  }

  isPageBusy() {
    return !this.pageservice.isPageFree();
  }

  showActionButton() {
    return true;
  }

  getPortalImageWithLine() {
    return "/assets/images/mylifeadicon.svg";
    // return "/assets/images/mylifead-logo.png";
  }

  getPortalImage() {
    return "/assets/images/mylifeadicon.svg";
  }

  getPortalImageAD() {
    return "/assets/images/mylifeadicon.svg";
    // return "/assets/images/mylifead-logo.png";
  }
  actionButtonText() {

    /*if (this.pageservice.canGoOnlineOffline()) {
      return this.pageservice.isOnline() ? "Go&nbsp;Offline" : "Go&nbsp;Online";
    }*/

    return this.isLogin() ? "Sign&nbsp;Out" : "Sign&nbsp;in";
  }

  actionButtonCommand() {

    /*if (this.pageservice.canGoOnlineOffline()) {
      return this.pageservice.isOnline() ? "gooffline" : "goonline";
    }*/

    return this.isLogin() ? "signout" : "signin";
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.pageservice.resetPageLayout();
  }

  isMenuFloating() {

    return this.pageservice.getMainContentWidth() >= 1024
    // return this.pageservice.getMainContentWidth() < 1150 || this.pageservice.getMainContentWidth() > 1150;
  }
  isToggleHidden() {
    return this.pageservice.getMainContentWidth() <= 1200 || this.pageservice.getLoginNavItems().filter(x => x.isvisible == true).length <= 0;
  }
  public showTitle() {
    return this.pageservice.doShowTitle()
  }
  getTitle(): string {
    let title = this.pageservice.pageTitle ? this.pageservice.pageTitle.replace(/ /g, "&nbsp;") : undefined;
    if (title == "Obituray")
      title = "Obituary";

    return title;
  }
  isLoginFloating() {
    return this.pageservice.getMainContentWidth() < 600;
  }

  isScreenSupported() {
    return this.pageservice.isScreenSupported();
  }

  getMenuItems() {
    return this.pageservice.getMenutItems();
  }

  getConfirmMessage() {
    return this.pageservice.confirmMessage;
  }

  getConfirmOptions() {
    return this.pageservice.confirmOptions;
  }

  // w3_close() {
  //   document.getElementById("mainnav_dom").style.display = "none";
  //   document.getElementById("myOverlay").style.display = "none";
  // }

  openMainNav() {
    if (this.pageservice.getMainContentWidth() > 1150) {
      this.pageservice.toggleMainNav();
    }
    else if (this.pageservice.getMainContentWidth() < 1150) {
      this.openMainNav2();
    }

  }

  openMainNav2() {
    this.pageservice.toggleMainNavSD();
  }

  closeMainNav() {
    //this.pageservice.closeMainNav();
  }

  confirmAction(action: any) {
    this.eventService.publish2("confirmation_dialog", action);
  }

  refresh() {
    //this.notificationservice.getAll(this.httpservice);
  }

  isDebug() {
    //return environment.debug;
  }
}


