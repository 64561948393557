import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MasterViewComponent } from './master-view.component';
import { DialogModule } from '../dialog/dialog.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { ToastModule } from 'primeng/toast'
import { MenuModule } from 'primeng/menu'
import { HttpService } from '../../services/http.service';
import { HttpClient, HttpHandler, HttpClientModule } from '@angular/common/http';
import { PageService } from '../../services/page.service';
import { MessageService } from 'primeng/api';
import { S3FileUploadModule } from 'src/app/logic/shared/components/s3-file-upload/s3-file-upload.module';
import { PipeModule } from 'src/app/logic/shared/pipe/pipe.module';
import { GenericMessageModule } from '../generic-message/generic-message.module';
import { AppUserSelectorModule } from '../app-user-selector/app-user-selector.module';
import { ProgressBarModule } from 'primeng/progressbar';
import { DialogModule as dg } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';


import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [MasterViewComponent],
  imports: [
    CommonModule,
    DialogModule,
    IconButtonModule,
    ToastModule,
    MenuModule,
    dg,
    FormsModule,
    CheckboxModule,
    HttpClientModule,
    ProgressBarModule,
    S3FileUploadModule,
    PipeModule,
    GenericMessageModule,
    AppUserSelectorModule,
    TooltipModule

  ],
  providers: [HttpService, PageService, MessageService],
  exports: [MasterViewComponent]
})
export class MasterViewModule { }
