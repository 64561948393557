import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { LoaderModule } from '../loader/loader.module';
import { ButtonModule } from '../button/button.module';
import { ProgressBarModule } from 'primeng/progressbar'
import { AlertMessageModule } from '../alert-message/alert-message.module';
import { IconButtonModule } from '../icon-button/icon-button.module';

@NgModule({
  declarations: [
    DialogComponent
  ],
  imports: [
    CommonModule,
    LoaderModule,
    ButtonModule,
    ProgressBarModule,
    AlertMessageModule,
    IconButtonModule
  ],
  exports: [
    DialogComponent
  ]
})
export class DialogModule { }
