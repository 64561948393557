import { String } from 'typescript-string-operations';
import { Helper } from './helper';
export class DateHelper {
   static localToUtc(date: Date): Date {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
   }

   static UtcToLocal(date: Date) {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
   }

   static GetLocaleDateFromUTC(obj: Date): string {
      return new Date(obj).toLocaleDateString();
   }

   static GetLocaleTimeFromUTC(obj: Date): string {
      return DateHelper.GetHoursFromDate(obj).toString() + ':' + DateHelper.GetMinutesFromDate(obj);
   }

   static GetLocaleAMPMFromUTC(date: Date): string {
      return new Date(date).getHours() < 12 ? 'AM' : 'PM';
   }

   static GetAMPMFromTime(hour: number): string {
      return (hour >= 12) ? hour + "PM" : hour + "AM";
   }

   static GetLocalDateStartUtcFromStr(date: string): Date {
      return new Date(date.trim());
   }

   static GetLocalDateEndUtcFromStr(date: string): Date {
      return DateHelper.AddDays(DateHelper.GetLocalDateStartUtcFromStr(date), 1);
   }

   static GetLocalDateStartFromDate(date: any): Date {
      if (date && typeof date == "string") {
         date = new Date(date);
      }
      return DateHelper.GetLocalDateStartUtcFromStr(DateHelper.GetLocaleDateFromDate(date));
   }

   static GetLocalDateEndFromDate(date: any): Date {
      if (date && typeof date == "string") {
         date = new Date(date);
      }
      return DateHelper.GetLocalDateEndUtcFromStr(DateHelper.GetLocaleDateFromDate(date));
   }

   static GetLocalTodayStartUtc(): Date {
      return DateHelper.GetLocalDateStartUtcFromStr(DateHelper.GetLocaleDate());
   }

   static GetLocalTodayEndUtc(): Date {
      return DateHelper.GetLocalDateEndUtcFromStr(DateHelper.GetLocaleDate());
   }

   static GetTimeZone(): string {
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return new Date().
         toLocaleString("en", { timeZoneName: "short", timeZone: timezone }).
         split(' ').
         pop();
   }

   static GetDuration(from: any, to: any): number {
      var timeStart: any = new Date(from);
      var timeEnd: any = new Date(to);
      var Difference_In_Time = Math.abs(timeStart - timeEnd) / 1000 / 60;
      return Difference_In_Time;
   }



   static GetLocalDateStartDayMonthYearUtc(localeday: number, localmonth: number, localyear: number): Date {
      return DateHelper.GetLocalDateStartUtcFromStr(localmonth.toString() + "/" + localeday.toString() + "/" + localyear.toString);
   }

   static GetLocalDateEndDayMonthYearUtc(localeday: number, localmonth: number, localyear: number): Date {
      return DateHelper.GetLocalDateEndUtcFromStr(localmonth.toString() + "/" + localeday.toString() + "/" + localyear.toString);
   }

   static GetLocaleDate(): string {
      let date = new Date();
      return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
   }

   static Gettime(obj: any) {
      return new Date(obj).getTime();
   }

   static GetLocaleDateTime(): Date {
      return new Date();
   }

   static GetLocaleDateTimeWithStepFive(): Date {
      let d: Date = new Date();
      let minutes = d.getMinutes();
      let diff: number = minutes % 5
      // if (diff > 0 && diff < 3) {
      //    return new Date(new Date().getTime() - diff * 60000)
      // }
      // else if (diff >= 3 && diff < 5) {
      //    return new Date(new Date().getTime() + (5 - diff) * 60000)
      // }
      // else {
      //    return new Date();
      // }

      if (diff == 0) {
         return new Date();
      }
      else {
         return new Date(new Date().getTime() + (5 - diff) * 60000);
      }
   }

   static GetDay(date): string {
      return date.getDate() < 10 ? "0" + date.getDate().toString() : date.getDate().toString()
   }

   static GetMonth(date): string {
      return (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
   }

   static GetYear(date): string {
      return (date.getFullYear()).toString();
   }

   static GetDateFromString(date): Date {
      return new Date(date);
   }

   static GetLocaleDateFromDate(date: Date): string {
      return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
   }

   static GetDateTimeMinsFromDate(date: Date): string {
      return date.getDate() + "-" + DateHelper.GetMonthNameFromDate(date) + "-" + date.getFullYear() + ' ' + DateHelper.GetHoursFromDate(date) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ' ' + DateHelper.GetLocaleAMPMFromUTC(date);
   }

   static GetDateOnly(Obj): string {
      return new Date(Obj).getDate().toString();
   }

   static GetFromToTimeFromDate(from: Date, to: Date) {
      return String.Format("{0}:{1} - {2}:{3}", from.getHours(), from.getMinutes(), to.getHours(), to.getMinutes());
   }

   static GetFromToAMPMTimeFromDate(from: Date, to: Date) {
      return String.Format("{0} - {1}", DateHelper.GetLocaleTimeFromUTC(from) + " " + DateHelper.GetLocaleAMPMFromUTC(from), DateHelper.GetLocaleTimeFromUTC(to) + " " + DateHelper.GetLocaleAMPMFromUTC(to));
   }

   static GetTimeFromString(date: string) {
      return DateHelper.GetTimeFromDate(new Date(date));
   }

   static GetTimeFromDate(date: Date) {
      return DateHelper.GetLocaleTimeFromUTC(date) + " " + DateHelper.GetLocaleAMPMFromUTC(date);
   }



   static GetFromToTimeFromString(fromstr: string, tostr: string) {
      let from = new Date(fromstr);
      let to = new Date(tostr);
      return String.Format("{0}:{1} - {2}:{3}", from.getHours(), from.getMinutes(), to.getHours(), to.getMinutes());
   }

   static GetAgeFromString(dob: string, tilldate: string = undefined): string {
      const bdate = new Date(dob);
      let endate = new Date();
      if (tilldate) {
         endate = new Date(tilldate);
      }

      var timeDiff = Math.abs(endate.getTime() - bdate.getTime());
      return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365).toString() + ' ' + 'years';
   }





   static GetHoursFromDate(date: Date) {
      let hours = new Date(date).getHours();
      if (hours == 0) {
         return 12;
      }
      if (hours <= 12) {
         return hours;
      }
      switch (hours) {
         case 13:
            return 1;
         case 14:
            return 2;
         case 15:
            return 3;
         case 16:
            return 4;
         case 17:
            return 5;
         case 18:
            return 6;
         case 19:
            return 7;
         case 20:
            return 8;
         case 21:
            return 9;
         case 22:
            return 10;
         case 23:
            return 11;
         case 24:
            return 12;
         default:
            return 12;
      }
   }

   static GetMinutesFromDate(date: Date): string {
      let minutes = new Date(date).getMinutes();
      if (minutes < 10) {
         return '0' + minutes.toString();
      }
      else {
         return minutes.toString();
      }
   }

   static GetDateArray(start, end) {
      let arr = new Array();
      let dtStart = new Date(start);
      var dtEnd = new Date(end);
      while (dtStart <= dtEnd) {
         arr.push(new Date(dtStart));
         dtStart.setDate(dtStart.getDate() + 1);
      }
      return arr;
   }

   static GetDateArrayToLocale(start, end): string[] {
      let arr = new Array();
      let dtStart = new Date(start);
      var dtEnd = new Date(end);
      while (dtStart <= dtEnd) {
         arr.push(DateHelper.GetLocaleDateFromUTC(new Date(dtStart)));
         dtStart.setDate(dtStart.getDate() + 1);
      }
      return arr;
   }

   static GetDayNameFromDate(obj: Date) {
      let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return days[new Date(obj).getDay()];
   }

   static GetMonthNameFromDate(obj: Date) {
      let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return month[new Date(obj).getMonth()];
   }

   static GetFormatDateDayWise(obj: Date): string {
      return DateHelper.GetDayNameFromDate(obj).slice(0, 3) + ', ' + DateHelper.GetMonthNameFromDate(obj).slice(0, 3) + ' ' + DateHelper.GetDateOnly(obj);
   }

   static GetDateFormatDayNameDateAndYear(obj: Date): string {
      return DateHelper.GetDayNameFromDate(obj).slice(0, 4) + ' ' + DateHelper.GetDateOnly(obj) + ', ' + DateHelper.GetYear(obj);
   }

   static GetCalculateAge(obj: Date): number {
      const bdate = new Date(obj);
      var timeDiff = Math.abs(Date.now() - bdate.getTime());
      return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
   }


   static GetNextDateArray(start, endIndex) {
      let arr = new Array();
      let dtStart = new Date(start);
      for (let i = 0; i < endIndex; i++) {
         dtStart.setDate(dtStart.getDate() + 1);
         arr.push(new Date(dtStart));
      }
      return arr;
   }

   static GetPrevDateArray(start, endIndex) {
      let arr = new Array();
      let dtStart = new Date(start);
      for (let i = 0; i < endIndex; i++) {
         dtStart.setDate(dtStart.getDate() - 1);
         arr.push(new Date(dtStart));
      }
      return arr.reverse();
   }

   static GetMinuteDiff(start: Date, end: Date): number {
      var diff = (start.getTime() - end.getTime()) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
   }

   static GetMinuteDiffNonAbs(start: Date, end: Date): number {
      var diff = (start.getTime() - end.getTime()) / 1000;
      diff /= 60;
      return Math.round(diff);
   }

   static AddDays(date: Date, days: number): Date {
      let t = date.getTime();
      t = t + (days * 24 * 60 * 60 * 1000);
      return new Date(t);
   }

   static MinusDays(date: Date, days: number): Date {
      let t = date.getTime();
      t = t - (days * 24 * 60 * 60 * 1000);
      return new Date(t);
   }

   //todo - Will Remove in Future After discussion with Haroon bhai
   static AddDaysStr(date: Date, days: number): Date {
      let t = new Date(date).getTime();
      t = t + (days * 24 * 60 * 60 * 1000);
      return new Date(t);
   }

   //todo - Will Remove in Future After discussion with Haroon bhai
   static MinusDaysStr(date: Date, days: number): Date {
      let t = new Date(date).getTime();
      t = t - (days * 24 * 60 * 60 * 1000);
      return new Date(t);
   }

   static GetTimeFromMinutes(timeofday: number): string {
      let hours1: any;
      let minutes1: any;
      hours1 = Math.floor(timeofday / 60);
      minutes1 = timeofday - (hours1 * 60);

      if (hours1.length == 1) {
         hours1 = '0' + hours1;
      }
      if (minutes1.length == 1) {
         minutes1 = '0' + minutes1;
      }
      if (minutes1 == 0) {
         minutes1 = '00';
      }
      if (hours1 >= 12) {
         if (hours1 == 12) {
            hours1 = hours1;
            minutes1 = minutes1 + " PM";
         } else {
            hours1 = hours1 - 12;
            minutes1 = minutes1 + " PM";
         }
      } else {
         hours1 = hours1;
         minutes1 = minutes1 + " AM";
      }
      if (hours1 == 0) {
         hours1 = 12;
         minutes1 = minutes1;
      }

      return hours1 + ':' + minutes1;
   }

   static getDateDisplayByDate(date: Date): string {
      return date.toDateString();
   }

   static getDateDisplayByString(date: string): string {
      if (!Helper.checkIsStringEmpty(date)) {
         //return (new Date(date)).toDateString();
         var d = new Date(date);
         return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
      }
      else {
         return undefined;
      }
   }

   static getDateDisplay(date: any): string {
      if (!Helper.checkIsStringEmpty(date)) {
         //return (new Date(date)).toDateString();
         var d = new Date(date);
         return d.getDate() + "-" + DateHelper.GetMonthNameFromDate(date) + "-" + d.getFullYear();
      }
      else {
         return undefined;
      }
   }


   static getYearMonthDateDisplay(date: any): string {
      if (!Helper.checkIsStringEmpty(date)) {
         var d = new Date(date);
         return d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getDate();
      }
      else {
         return undefined;
      }
   }

   static getDateOnlyDisplayByString(date: string) {
      if (!Helper.checkIsStringEmpty(date)) {
         var d = new Date(date);
         d = DateHelper.UtcToLocal(d);
         return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
      }
      else {
         return undefined;
      }
   }

   static getDateTimeDisplayByString(date: string): string {
      if (!Helper.checkIsStringEmpty(date)) {
         return DateHelper.getDateTimeDisplayByDate(new Date(date));
      }
      else {
         return undefined;
      }
   }

   static getDateTimeDisplayByDate(date: Date): string {
      if (date !== undefined) {
         return date.toDateString() + " " + DateHelper.GetTimeFromDate(date);
      }
      else {
         return undefined;
      }
   }

   static getElapsedTimeOrDateTimeByString(date: string): string {
      return DateHelper.getElapsedTimeOrDateTimeByDate(new Date(date));
   }

   static getElapsedTimeOrDateTimeByDate(date: Date): string {
      let d = new Date();
      let diff = DateHelper.GetMinuteDiffNonAbs(date, d);
      if (diff > -60 && diff < 0) {
         diff = Math.abs(diff);
         return diff.toString() + " mins. ago"
      }
      else if (diff > 0 && diff < 60) {
         return diff.toString() + " mins. after"
      }
      else if (diff == 0) {
         return "Just now";
      }

      return DateHelper.getDateTimeDisplayByDate(date);
   }

   static GetDateDifferenceinDays(toDate: any, fromdate: any) {
      if (toDate && typeof toDate == "string") {
         toDate = new Date(toDate);
      }
      if (fromdate && typeof fromdate == "string") {
         fromdate = new Date(fromdate);
      }
      if (fromdate) {
         fromdate = DateHelper.UtcToLocal(fromdate);
      }

      if (toDate) {
         toDate = DateHelper.UtcToLocal(toDate);
      }
      if (toDate && fromdate) {
         let diffTime: any = Math.abs(toDate - fromdate);
         return (Math.ceil(diffTime / (1000 * 60 * 60 * 24))) + 1;
      }
      else {
         return undefined;
      }
   }

   static GetOffSetFromTime(time: string) {
      if (!Helper.checkIsStringEmpty(time)) {
         let hours: number = +(time.split(':')[0]);
         let minutes: number = +(time.split(' ')[0].split(':')[1]);
         let timeformat = time.split(' ')[1];

         if (hours == 12 && timeformat == "AM") {
            hours = 0;
         }
         else if (hours == 12 && timeformat == "PM") {
            hours = 12
         }

         if (timeformat == "PM" && hours < 12 && hours != 12) {
            hours = hours + 12;
         }
         return ((hours * 60) + minutes);
      }
   }

   static GetLocalDateTimeWithZone() {
      let d = new Date().toString().split(' ');
      let timezone: string = "";
      for (let i = 0; i < d.length; i++) {
         if (i > 5) {
            timezone = timezone + " " + d[i];
         }
      }
      return timezone.trim().substr(1).slice(0, -1);
   }
}


