import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs';


@Injectable()
export class EventService {
  private messagesSource = new Subject<Message>();

  private messages = this.messagesSource.asObservable();

  public publish(message: Message) {
    this.messagesSource.next(message);
  }

  public publish2(type: string, data: any) {
    this.messagesSource.next(new Message(type, data));
  }

  public publish3(type: string) {
    this.messagesSource.next(new Message(type, undefined));
  }

  public observable(): Observable<Message> {
    return this.messagesSource.asObservable();
  }
}

export class Message {
  constructor(public type: string, public data: any) {
  }
}
