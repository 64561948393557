import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Helper } from '../helpers/helper';

@Injectable()

export class NavigationService {
    constructor(private router: Router) {
    }

    private resetService() {
        this.params = undefined;
        this.selectedNav = undefined;
        this.savedParams = {};
        this.lastRoute = {};
    }

    private params: any;

    private savedParams: { [id: string]: any; } = {};

    private lastRoute: { [id: string]: string; } = {};

    route(route: string) {
        this.params = undefined;
        this.router.navigate([route]);
    }

    routeToID(route: string, obj: any) {
        this.router.navigate([route, obj]);
    }

    routeParams(route: string, params: string[]) {
        this.params = params;
        this.router.navigate([route, ...params]);
    }

    routeUnique(route: string, data: any = undefined) {
        let key = Helper.getID();
        this.lastRoute[route] = key;
        this.savedParams[key] = data;
        this.router.navigate([route, key]);
        this.params = data;
    }

    getParams() {
        return this.params;
    }

    getUniqueParams(keyid: string) {
        return this.savedParams[keyid];
    }

    isLastRoute(route: string, keyid: string) {
        return this.lastRoute[route] == keyid;
    }

    testroute(route: any) {
        // const url = this.router.serializeUrl(
        //     this.router.createUrlTree([route])
        // );

        window.open(route, '_self');
    }
    selectedNav: string;
}
