<div #bsdg [id]="id" class="modal" tabindex="-1" role="dialog" data-backdrop="static" [ngStyle]="{'z-index': zIndex}">
    <div class="modal-dialog modal-dialog-centered" [ngClass]="getModalClass()">
        <div class="modal-content">
            <div class="modal-header">
                <div class="mb-auto mt-2 wdth10" *ngIf="showBackButton">
                    <div class="float-left">
                        <app-icon-button [size]="2" *ngIf="!hideCloseButton" iconClass="text-medium fal fa-arrow-left" (clicked)="onBack()"></app-icon-button>
                    </div>
                </div>
                <span class="mb-auto mt-3" *ngIf="!showBackButton">&nbsp;</span>
                <span class="my-auto title pt-5 dialg_fsize24">{{title}}</span>
                <div class="mb-auto mt-2 wdth10">
                    <div class="float-right">
                        <app-icon-button [size]="2" *ngIf="!hideCloseButton" iconClass="text-medium fal fa-times" (clicked)="close()"></app-icon-button>
                    </div>
                </div>
            </div>
            <p-progressBar *ngIf="isBusy" mode="indeterminate"></p-progressBar>
            <div class="dialg_h3" *ngIf="showHeader && !isBusy">&nbsp;</div>
            <div class="modal-body" [ngClass]="getClass()">
                <div *ngIf="timeZoneText && !isLoading" class="text-center mb-1">{{timeZoneText}} </div>
                <div class="dialg_pfixd">
                    <app-icon-button [size]="2" *ngIf="!hideCloseButton" iconClass="text-light far fa-times-circle" (clicked)="close()"></app-icon-button>
                </div>
                <ng-content *ngIf="!isLoading"></ng-content>
                <div class="text-center my-3" *ngIf="isLoading">
                    <h5>We are getting things ready</h5>
                    <span class="dialg_fsize3" class="fad fa-circle-notch fa-spin my-3 text-dark"></span>
                    <h4 class="font-weight-bold">Please wait while we gather data</h4>
                </div>
            </div>
            <div *ngIf="showFooter" class="modal-footer d-flex dialg_minh" [ngClass]="customFooter ? 'justify-content-between' : 'justify-content-center'">
                <ng-content *ngIf="!isLoading" select="[dg-footer]"></ng-content>
            </div>
        </div>
    </div>
    <app-loader [show]="!isLoading && isBusy"></app-loader>
    <div class="px-3 py-1 bg-white text-strong rounded border-light dialg_pfixdt0" *ngIf="!isLoading && isBusy">
        <span class="fas fa-circle-notch fa-spin text-medium"></span>
        Loading
    </div>
</div>

<div *ngIf="NavigateToLogin()" #bsdg [id]="id" class="modal" tabindex="-1" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" [ngClass]="getModalClass()">
        <div class="modal-content">
            <div class="modal-header d-flex">
                <img class="dialg_h30" src="/assets/images/watermark.svg">
                <span class="my-auto title">Sign in required</span>
                <div class="my-auto">
                    <app-icon-button [size]="2" iconClass="text-medium far fa-times-circle" (clicked)="close()"></app-icon-button>
                </div>
            </div>
            <div class="modal-body" [ngClass]="getClass()">
                <div class="row">
                    <div class="col-12 form-group">
                        <app-alertmessage message="Please sign in to schedule an appointment now"></app-alertmessage>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-flex dialg_minh">
                <app-button title="Ok" cssClass="btn btn-primary btn-block" (clicked)="close()"></app-button>
                <app-button title="Sign in" cssClass="btn btn-primary btn-block" (clicked)="Route()"></app-button>
            </div>
        </div>
    </div>
</div>