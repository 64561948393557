import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceUsageBarComponent } from './resource-usage-bar.component';
import { DashboardService } from '../../services/dashboard.service';
import { PageService } from 'src/app/core/services/page.service';



@NgModule({
  declarations: [ResourceUsageBarComponent],
  imports: [
    CommonModule
  ],
  providers:[
              DashboardService
            ],
  exports: [ResourceUsageBarComponent]
})
export class ResourceUsageBarModule { }
