import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntitysidebarComponent } from './entitysidebar.component';



@NgModule({
  declarations: [EntitysidebarComponent],
  imports: [
    CommonModule
  ],
  exports: [EntitysidebarComponent]
})
export class EntitysidebarModule { }
