import { Component, Input, OnDestroy } from '@angular/core';
import { PageService } from '../../services/page.service';
import { environment } from 'src/environments/environment';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EventService } from '../../services/event.service';
import { ConfigService } from '../../../logic/shared/services/config.service';
@Component({
  selector: 'app-contentview',
  templateUrl: './content-view.component.html',
  styleUrls: ['./content-view.component.scss']
})
export class ContentViewComponent implements OnDestroy {
  cars: SelectItem[];

  @Input() navColSize: number = 0;

  @Input() hideHeader: boolean = false;

  year: number = (new Date()).getFullYear();

  UserAccountType: string = "";
  subscription: Subscription;

  constructor(public pageservice: PageService,
    public eventService: EventService) {
    this.subscription = this.eventService.observable().subscribe(data => {
      if (data.type == 'Profile_Changed') {
        this.UserAccountType = data.data.accounttype;
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  text: string;
  showMintanceMode() {
    if (ConfigService.notLive == 1) {
      this.text = ConfigService.notLiveMessage;
      return true
    }
    else if (ConfigService.notLive == 2) {
      this.text = ConfigService.notLiveMessage;
      return true;
    }
  }
  getListOfUserProfile() {
    if (this.pageservice.isLogin())
      return this.pageservice.getListOfProfile();
  }
  getTitle(): string {
    return this.pageservice.pageTitle ? this.pageservice.pageTitle.replace(/ /g, "&nbsp;") : undefined;
  }
  private pageExpired(): boolean {
    return this.pageservice.isExpired;
  }

  public showTitle() {
    return this.pageservice.doShowTitle()
  }

  private pageFault(): string {
    return !this.pageservice.isSubPage && this.pageservice.pageFault;
  }

  pageErrorMessage(): string {
    return this.pageExpired() ? "This page content is no longer valid." : this.pageFault();
  }

  getPortalImage() {
    return "/assets/images/mylifeadicon.svg";
  }
  pageNotLoaded() {
    return this.pageExpired() || this.pageFault();
  }

  logout() {
    if (this.pageservice.isPageFree() && this.pageservice.isPageReadyForRedirect()) {
      this.pageservice.logout();
      this.pageservice.route("/auth");
    }
  }

  onProfile() {
    this.pageservice.route("profile");
  }
  openMainNav() {
    this.pageservice.openMainNav();
  }
  getLoginUserImage() {
    return this.pageservice.getDisplayLoginImage() ? this.pageservice.getDisplayLoginImage() + '?rnd=' + this.pageservice.getRandom() : '/assets/images/profile-icon.png' + '?rnd=' + this.pageservice.getRandom();
  }
  getDisplayLoginNames() {
    return this.pageservice.getLoginName();
  }
  getLoginUserAge() {
    return this.pageservice.getDisplayLoginAge();
  }


  getLoginUsergGender() {
    return this.pageservice.getDisplayLoginGender();
  }
  isFullPage() {
    return this.pageservice.isFullPage();
  }

  openSubNav() {
    this.pageservice.openSubNav();
  }

  closeSubNav() {
    this.pageservice.closeSubNav();
  }

  isSubNavFloating() {
    return this.navColSize > 0 && (this.pageservice.getMainContentWidth() - this.navColSize) < this.pageservice.minSupportedScreen;
  }

  getSubNavText(): string {
    return this.pageservice.subNavText;
  }

  getNavClass(): string {
    return this.isSubNavFloating() ? "ontop ui-sidebar ui-sidebar-left border-right" : "border-right";
  }

  pageLoading() {
    return !this.pageservice.isPageReady() && !this.pageservice.isSubPage;
  }

  subPageLoading() {
    return !this.pageservice.isPageReady() && this.pageservice.isSubPage;
  }

  showResourceUsageBar() {
    return this.pageservice.showResourceUsageBar;
  }

  showPage(): boolean {
    return !this.pageLoading() && !this.pageNotLoaded();
  }

  getNavSize() {
    return this.navColSize;
  }

  isPageBusy() {
    return !this.pageservice.isPageFree();
  }

  showLoader() {
    return !this.pageLoading() && !this.subPageLoading() && this.isPageBusy();
  }

  getContentClass() {
    return this.pageservice.isFullPage() ? "full-page" : "not-full-page pb-5";
  }

  getNoContentClass() {
    return this.pageservice.isFullPage() ? "full-page pt-5" : "not-full-page pt-3";
  }

  isScreenSupported() {
    return this.pageservice.isScreenSupported();
  }

  getPadding() {
    if (this.pageservice.getPageWidth() == 3) {
      return 0;
    }
    else if (this.pageservice.getPageWidth() == 2) {
      if (this.pageservice.resolutionX >= 1360) {
        return 200;
      }
      else if (this.pageservice.resolutionX >= 1200) {
        return 100;
      }
      else {
        return 25;
      }
    }
    else {
      if (this.pageservice.resolutionX >= 1360) {
        return 300;
      }
      else if (this.pageservice.resolutionX >= 1200) {
        return 150;
      }
      else {
        return 50;
      }
    }
  }

  isDebug() {
    return environment.debug;
  }
  NavigatePlayStore() {
    window.open("https://play.google.com/store/apps/details?id=com.jhonson.mylifead");
  }

  NavigateAppleStore() {
    window.open("https://apps.apple.com/us/app/mylife-ad/id6444675290");
  }
}
