import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { BrowserModule } from '@angular/platform-browser';
import { MasterViewModule } from 'src/app/core/components/master-view/master-view.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContentViewModule } from 'src/app/core/components/content-view/content-view.module';
import { AngularDraggableModule } from 'ngx-draggable-resize';
import { ConfigService } from '../shared/services/config.service';
import { PageService } from 'src/app/core/services/page.service';
import { NavigationService } from 'src/app/core/services/navigation';
import { EventService } from 'src/app/core/services/event.service';
import { CacheService } from 'src/app/core/services/cache.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { DeepcloneService } from '../shared/services/deepclone.service';
import { PendingProcessingGuardService } from '../../core/services/pending-processing-guard.service';
export function initializeApp(appConfig: ConfigService) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [AdminComponent
  ],
  bootstrap: [AdminComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    BrowserModule,
    MasterViewModule,
    BrowserAnimationsModule,
    ContentViewModule,
    AngularDraggableModule,
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [ConfigService], multi: true,
  },
    PageService, NavigationService, EventService, CacheService, StorageService, DeepcloneService, PendingProcessingGuardService],
})
export class AdminModule { }
