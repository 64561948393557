import { Component, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.iconClass && changes.iconClass.currentValue && changes.iconClass.currentValue.indexOf("text-") < 0) {
      this.iconClass = this.iconClass + " text-primary";
    }

    if (changes.isDisabled) {
      if (changes.isDisabled.currentValue == true)
        this.iconClass = this.iconClass + " text-light";
      else
        this.iconClass = this.iconClass?.replace("text-light", "");
    }

    if (changes.size && changes.size.currentValue && changes.size.currentValue == 2) {
      this.iconSize = 1.4;
      this.textSize = 0.9;
    }
    if (changes.size && changes.size.currentValue && changes.size.currentValue == 3) {
      this.iconSize = 1.75;
    }

    if (this.isDisabled) {
      this.cssClass += " disabled-button"
    }
    else {
      if(this.cssClass)
        this.cssClass = this.cssClass.replace(" disabled-button", "")
    }
  }

  constructor() { }

  @Input() title: string;

  @Input() showTitle: boolean = false;

  @Input() showBorder: boolean = false;

  @Input() cssClass: string;

  @Input() textClass: string;

  @Input() iconClass: string;

  @Input() isBusy: boolean = false;

  @Input() isDisabled: boolean = false;

  @Input() inline: boolean = true;

  @Input() isButton: boolean = false;

  @Output() clicked = new EventEmitter<any>();

  @Input() size: number = 1;

  @Input() iconSize: number = 1.2;

  textSize: number = 1;


  onClick(event: any) {
    if (this.isDisabled === false || this.isDisabled === undefined) {
      this.clicked.emit(event);
    }

    return false;
  }
}
