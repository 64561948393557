import { Injectable } from '@angular/core';
import { NavigationService } from './navigation';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { Helper } from '../helpers/helper';
import { Subject } from 'rxjs';
import { DialogComponent } from '../components/dialog/dialog.component';
import { CacheService } from './cache.service';
import { EventService } from './event.service';
import { StorageService } from './storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { JwtToken } from 'src/app/logic/shared/models/jwttokeninfo';
import { EntityScheduleDetailsReponse } from 'src/app/logic/shared/models/entityrequestresponse';
declare var $: any;

@Injectable()

export class PageService {
  entityscheduledetailsres: EntityScheduleDetailsReponse.Response[] = [];
  tokeninfo: JwtToken.Info = {} as JwtToken.Info

  viewableModulesCount = 0;
  private _isLogin: boolean = false;

  private _isOnline: boolean = false;

  private _loginName: string;

  private _displayLoginNames: string[] = ["Guest User"];

  private _authToken: string;

  private _loginUserID: string;

  private _loginUserImage: string;

  private _loginUserAge: string;

  private _loginUserGender: string;


  private _width: number = 3;

  private _AdminStatus: string;

  private _dasbhoardStyle: string;

  isExpired: boolean = false;

  pageFault: string = undefined;

  subNavText: string;

  minSupportedScreen: number = 1024;

  private isfullapge: boolean = false;

  public hasUnsavedChanges: boolean = true;

  socialProviderName: string;

  socialProvidertoken: string;

  showResourceUsageBar: boolean = true;

  entitychange: boolean = false;

  isvideostart: boolean = false;

  pendinggaurdroute: string = "";

  isusernumberupdate: boolean = false;

  isentityschedulescreen: boolean = false;

  _isAdmin: boolean = false;

  currentY: number = 0;

  excludeRoute: string[] = ['googlecontactsync'];

  uuid: string;

  loginNavItems: any = [];

  userPurchases: boolean = false;

  isNetwrokCallByPass: boolean = false;

  selectedUser: any;

  _userLoginTime: any;

  _token2FA: boolean = false;

  _twoFA: boolean = false;

  changeProfileFlag: boolean = false;

  isPaymentScreenShow: boolean = true;

  dashboardresult: any;
  idcounter: number = 1;

  public modulesList: any[];
  public userPurchasesData: any[];
  public restrictedModules: any[];
  public selectedRestrictedModule: any;
  public isRestrictedModule: boolean = false;
  private _MainAdminStatus: boolean;
  paymenturl: string = '';
  isFromPaymentRenewal: boolean = false;

  constructor(private messageService: MessageService, private router: ActivatedRoute, private troute: Router, public navservice: NavigationService, private storageservice: StorageService, private cacheservice: CacheService, private eventservice: EventService /*signalr: SignalRService,  private eventservice: EventService, private notificationservice: NotificationService, private cacheservice: CacheService, private storageservice: StorageService*/) {
    console.log('PageService Load');
    this.eventservice.observable().subscribe(x => {
      if (x.type == "SIGNED_OUT" || x.type == "SIGNING_IN") {
        this.resetService();
      }
    });

    setTimeout(() => {
      this.tryLogin();
      this.UserProfiles();
    }, 50);
    this.setRandomId();
  }

  rnd: any;

  setRandomId() {
    this.idcounter = this.idcounter + 1;
    this.rnd = parseInt(Helper.datepipe.transform(Date.now(), "HHmmssSSS") + this.idcounter.toString()).toString();
  }

  getRandom() {
    return this.rnd;
  }

  isActiveSubscription: boolean = true;

  checkAccountProcess() {
    if (this.isLogin()) {
      if (this.IsAdminUser() && this.getAdminStatus() != "deceased") {
        this.route("/accountprocess");
      }
      else if (!this.GetUserPurchases() && !this.IsAdminUser() && this.UserProfiles.length <= 1 && !this.isFromPaymentRenewal) {
        this.showErrorMsg("No active purchase, please check your email for futher instruction.")
        this.isActiveSubscription = false;
        this.logout()
      }
      else if (!this.GetUserPurchases() && !this.IsAdminUser() && this.UserProfiles.length > 1) {
        this.route("/accountprocess");
      }
    }
    // else {
    //   this.showInfoMsg('You need to "Sign In" to access this feature');
    //   this.troute.navigate(["auth"]);
    // }
  }

  setDahboardData(items: any) {
    this.dashboardresult = items;
  }

  getDashboardData() {
    this.dashboardresult;
  }

  setSelectedUser(item: any) {
    this.selectedUser = item;
  }

  getSelectedUser() {
    return this.selectedUser;
  }

  setLoginNavItems(items: any) {
    if (this.loginNavItems == undefined)
      this.loginNavItems = [];
    this.loginNavItems = items;
  }
  getLoginNavItems() {
    return this.loginNavItems
  }

  selectedcriteria: any
  setVirtualVaultSelectionCriteria(items: any) {
    this.selectedcriteria = items;
  }
  getVirtualVaultSelectionCriteria() {
    return this.selectedcriteria
  }

  virtualvaultcatgroup: any;
  setVirtualVaultCatAndGroup(items: any) {
    this.virtualvaultcatgroup = items;
  }
  getVirtualVaultCatAndGroup() {
    return this.virtualvaultcatgroup
  }

  SetCurrentY(input: number) {
    this.currentY = input;
  }

  GetCurrentY() {
    return this.currentY;
  }

  SetRedirecttUrl(url: string) {
    this.storageservice.setGlobalData("redirectUrl", url, 5);
  }

  GetRedirectUrl(): string {
    return this.storageservice.getGlobalData("redirectUrl");

  }
  setNewtworkCallBypass(callBypass: boolean) {
    return this.isNetwrokCallByPass = callBypass;
  }
  getNetworkCallByPass() {
    return this.isNetwrokCallByPass;
  }
  ClearRedirectUrl() {
    this.storageservice.removeGlobalData("redirectUrl");
  }

  route(route: string) {
    this.navservice.route(route);
  }

  routePendingGaurd() {
    this.navservice.route(this.pendinggaurdroute);
  }

  setModuleId(input: number) {
    this.cacheservice.setCacheData('ModuleID', input);
  }

  removeModuleId() {
    this.cacheservice.removeCacheData('ModuleID');
  }

  getModuleId() {
    return this.cacheservice.getCacheData('ModuleID');
  }

  setModuleName(input: string) {
    this.cacheservice.setCacheData('ModuleName', input);
  }

  getModuleName() {
    return this.cacheservice.getCacheData('ModuleName');
  }

  setMenuItem(itme: any) {
    this.menuItem = itme;
  }

  getMenuItem() {
    return this.menuItem;
  }
  private menuItem: any;

  private resetService() {
    this.logout(true);
  }
  tryLogin(data?: any, isRouteRefresh: boolean = false): boolean {
    this.setPageNotReady();
    if (!data) {
      data = this.cacheservice.getCacheData("sessiondata");
    }
    if (data && data.isLogin && data.Data) {
      this.eventservice.publish3("SIGNING_IN");
      this.uuid = data.Data.user.uuid;
      this._authToken = data.Data.token.access_token;
      this.tokeninfo = jwt_decode(this._authToken);
      this._AdminStatus = this.tokeninfo.Admin_Status;
      this._token2FA = this.tokeninfo.twofa;
      this._MainAdminStatus = this.tokeninfo.ismainadmin;
      this._isLogin = true;
      this._userLoginTime = data.Data.user.currentlogin;
      this._loginName = data.Data.user.fullname;
      this._loginUserID = data.Data.user.uuid;
      this._loginUserAge = data.Data.user.age;
      this._loginUserGender = data.Data.user.gender.name;
      this._loginUserImage = data.Data.user.profilepicture;
      this._isAdmin = data.Data.user.isAdmin;
      this.userPurchases = data.Data.user.user_purchases.length > 0 ? true : false;
      this.userPurchasesData = data.Data.user.user_purchases;
      this.restrictedModules = data.Data.user.restrictedModules;
      this.paymenturl = data.Data.renewpaymenturl;

      this.cacheservice.setCacheData("sessiondata", data);
      this.eventservice.publish2("SIGNED_IN", { authToken: this._authToken, userID: this._loginUserID, isAdmin: this._isAdmin, AdminStatus: this._AdminStatus });
      this.eventservice.publish3("HIDE_ALL_MENU");
      this.modulesList = data.Data.user.modules.filter(x => { return x.canview });
      console.log('xxx', this.modulesList);
      this.viewableModulesCount = this.modulesList.length;
      this.modulesList.forEach(element => {
        this.eventservice.publish2("SHOW_MENU", element.id);
      });
      this.eventservice.publish3("MENU_UPDATE");
    } else if (!Helper.checkIsStringEmpty(environment.impersonateAuthToken)) {
      this.eventservice.publish3("SIGNING_IN");
      this._isLogin = true;
      this._userLoginTime = data.Data.user.currentlogin;
      this._authToken = environment.impersonateAuthToken;
      this.tokeninfo = jwt_decode(this._authToken);
      this._AdminStatus = this.tokeninfo.Admin_Status;
      this._MainAdminStatus = this.tokeninfo.ismainadmin;
      this._loginName = environment.impersonateLoginName;
      this._loginUserID = environment.impersonateLoginUserID;
      this.eventservice.publish2("SIGNED_IN", { authToken: this._authToken, userID: this._loginUserID });
      // this.modulesList = data.Data.user.modules.filter(x => x.canview)
      // this.viewableModulesCount = this.modulesList.length;
      this.eventservice.publish3("HIDE_ALL_MENU");
      this.modulesList = data.Data.user.modules.filter(x => { return x.canview });
      this.viewableModulesCount = this.modulesList.length;
      this.modulesList.forEach(element => {
        this.eventservice.publish2("SHOW_MENU", element.id);
      });
      this.eventservice.publish3("MENU_UPDATE");
    }
    if (data && data.Data) {

      var pathname = new URL(window.location.href).pathname;
      var str = pathname.split("/");

      if (data.Data.user.adminid == null || data.Data.user.id == data.Data.user.adminid) {
        this.eventservice.publish3("SHOW_ALL_MENU");
        if (this.excludeRoute.includes(str[2])) {
          return
        }
        if (isRouteRefresh) {
          setTimeout(() => {
            console.log("**** going to route dashboard");
            this.route("dashboard");
            // this.ShowLeftNavBar();
            if (!this.IsAdminUser() && this.GetUserPurchases() && this.loginNavItems.filter(x => x.isvisible == true).length > 0) {
              console.log("animate padding ")
              this.ShowLeftNavBar();
              $('#maincontentdiv_dom').css({ paddingLeft: "80px" }, 250);
            }
            else {
              $('#maincontentdiv_dom').css({ paddingLeft: "0px" }, 250);
            }
            if (environment.isAdminUI) {
              console.log('********** Admin Side Bar Work');
              this.ShowLeftNavBar();
              $('#maincontentdiv_dom').css({ paddingLeft: "80px" }, 250);
            }
            this.setPageReady();
          }, 500);
        }
      }
      else {
        if (this._AdminStatus != "deceased") {
          this.route("/accountprocess");
        }
        else {


          if (isRouteRefresh && this.modulesList.length > 0) {
            let tRoute = this.modulesList[0].route;
            setTimeout(() => {
              console.log("********* going to route", tRoute);
              // if (this.IsAdminUser() && !this.GetUserPurchases() && this.loginNavItems.filter(x => x.isvisible == true).length > 0) {
              //   this.ShowLeftNavBar();
              // }
              /*if (this.IsAdminUser() && !this.GetUserPurchases() && this.loginNavItems.filter(x => x.isvisible == true).length > 0) {
                this.ShowLeftNavBar();
              }*/
              if (this.IsAdminUser() && this.loginNavItems.filter(x => x.isvisible == true).length > 0) {
                this.ShowLeftNavBar();
              }
              this.route(tRoute + "/list");
              this.setPageReady();
            }, 500);
          }
          else if (this.modulesList.length <= 0) {
            this.setPageReady();
          }
        }
      }
    }

    this.setDisplayLoginNames();

    setTimeout(() => {
      if (this._isLogin && !environment.isAdminUI)
        this.checkAccountProcess();
    }, 200);
    return this._isLogin;
  }

  setWalkThroughUrl(url: any) {
    this.walkThroughUrl = url
  }

  getWalkThroughUrl() {
    return this.walkThroughUrl;
  }

  walkThroughUrl: any
  iswalkthrough: boolean = false;
  setWalkThroughButtonShow(isbtnshow: boolean) {
    this.iswalkthrough = isbtnshow;
  }

  isWalkThroughButton() {
    return this.iswalkthrough;
  }
  setDashboardStyle(input: string) {
    this._dasbhoardStyle = input;
  }

  getDashboardStyle() {
    if (this._dasbhoardStyle)
      return this._dasbhoardStyle.replace('rounded', '');
    else
      return "";
  }

  setTwoFARequired(twofa: boolean) {
    this._twoFA = twofa;
  }

  getModuleTwoFARequired() {
    return this._twoFA;
  }

  isTokenTwoFARequired() {
    return this._token2FA;
  }
  GetCurrentLoginTime() {
    return this._userLoginTime;
  }
  GetUserPurchases() {
    return this.userPurchases;
  }

  getAdminStatus() {
    return this._AdminStatus;
  }
  UserProfiles(data?: any) {
    if (!data) {
      data = this.cacheservice.getCacheData("profilelists");
    }
    if (data && data.Data)
      this.eventservice.publish3("SIGNING_IN_User_Profile");
    this.cacheservice.setCacheData("profilelists", data);
  }
  getListOfProfile() {
    let cacheData = this.cacheservice.getCacheData("profilelists");
    if (cacheData != undefined) {
      return cacheData.Data;
    }
    else {
      return;
    }
  }
  getLoggedInUserProfile() {
    return this.cacheservice.getCacheData("sessiondata").Data;
  }


  getAdminProfileCount() {
    let cacheData = this.cacheservice.getCacheData("profilelists").Data;
    let temp = cacheData.filter(x => {
      return x.accounttype == "Admin Account";
    }).length;
    return temp
  }

  getDisplayLoginNames() {
    return this._displayLoginNames;
  }

  getDisplayLoginGender() {
    //let cachedata = this.cacheservice.getCacheData("sessiondata");
    return this._loginUserGender
  }

  setPendingGaurdRoute(input: string) {
    this.pendinggaurdroute = input;
  }

  getPendingGaurdRoute() {
    return this.pendinggaurdroute;
  }

  getDisplayLoginAge() {
    // let cachedata = this.cacheservice.getCacheData("sessiondata");
    return this._loginUserAge
  }

  getLoggedInUserId() {
    return this.uuid;
  }

  IsAdminUser() {
    return this._isAdmin;
  }

  IsMainAdminUser() {
    return this._MainAdminStatus;
  }


  getDisplayLoginImage() {
    return this._loginUserImage;
  }


  setDisplayLoginImage(imgurl: any) {
    this.CacheUpdate(imgurl);
    this._loginUserImage = imgurl;
  }

  setPaymentScreenShow(ispaymentscreenshow: boolean = true) {
    this.isPaymentScreenShow = ispaymentscreenshow;
  }

  getispaymentscreenshow() {
    return this.isPaymentScreenShow;
  }

  setEntitySentHistory(entity: EntityScheduleDetailsReponse.Response[]) {
    this.entityscheduledetailsres = entity;
  }
  getEntitySentHistory() {
    return this.entityscheduledetailsres;
  }

  CacheUpdate(imgurl) {
    let cachedata = this.cacheservice.getCacheData("sessiondata");
    cachedata.Data.user.profilepicture = imgurl
    this.cacheservice.setCacheData("sessiondata", cachedata);
  }


  private setDisplayLoginNames() {
    if (this._loginName) {
      this._displayLoginNames = this._loginName.split(" ").slice(0, 2);
    }
    else {
      this._displayLoginNames = ["Guest User"];
    }
  }

  getSocialProviderName(): string {
    return this.socialProviderName;
  }

  getAuthToken(): string {
    return this._authToken;
  }

  getDecodedToeknInfo(token: any) {
    return jwt_decode(token);
  }

  RefreshToken() {
    return this._authToken = "";
  }

  /*canGoOnlineOffline() {
    return this.isLogin() && environment.type == PortalType.Provider && this._userTypeID == "1";
  }*/

  isLogin(): boolean {
    return this._isLogin;
  }

  getLoginName(): string {
    let cachedata = this.cacheservice.getCacheData("sessiondata");
    if (cachedata)
      return this._loginName = cachedata.Data?.user.fullname;
    // return
  }

  getLoginUserID(): string {
    return this._loginUserID;
  }

  isOnline(): boolean {
    return this._isOnline;
  }

  setOnline(isonline: boolean) {
    this._isOnline = isonline;
  }

  logout(dontfireevent: boolean = false) {
    this._isLogin = false;
    this._authToken = undefined;
    this._loginName = undefined;
    this._loginUserID = undefined;

    this.cacheservice.removeCacheData("sessiondata")
    if (!dontfireevent) {
      this.eventservice.publish3("SIGNED_OUT");
    }

    this.setDisplayLoginNames();
  }

  private leftNavSize: number = 2;

  hideResoureceUsage: boolean = false;
  public setHideResourceUsage(hideresoureceusage: boolean) {
    return this.hideResoureceUsage = hideresoureceusage
  }

  public isHideResopurceUsage() {
    return this.hideResoureceUsage;
  }
  public hideLeftNav() {
    this.leftNavSize = 0;
    $('#maincontentdiv_dom').css({ paddingLeft: "0px" });
  }

  public hideLeftNavBar() {
    setTimeout(() => {
      $('#maincontentdiv_dom').css({ paddingLeft: "0px" }, 250);
      $("#mainnav_dom").removeClass("ui-sidebar-active");
    }, 8);

  }


  public ShowLeftNavBar() {
    setTimeout(() => {
      $('#maincontentdiv_dom').animate({ paddingLeft: "80px" }, 250);
      $("#mainnav_dom").addClass("ui-sidebar-active");
    }, 5);
  }

  public showLeftNav(colsize: number = 2) {
    this.leftNavSize = colsize;
  }

  public getLeftNavSize(): number {
    return this.leftNavSize;
  }


  private msg_display_time: number = 5000;

  private msg_dict: any = {};

  setMessageDIc(message: any) {
    this.msg_dict = message;
  }

  setMessageDisplayTime(time: any) {
    this.msg_display_time = time;
  }
  public showSuccessMsg(msg: string, summary: string = "Success") {
    this.addMessage(msg, summary, 'success');
  }

  public showErrorMsg(msg: string, summary: string = "Failure", severity: string = '', id: string = 'toast-error-msg') {
    this.addMessage(msg, summary, severity, id, 10000000);
  }

  public showInfoMsg(msg: string, summary: string = "Information") {
    this.addMessage(msg, summary, 'info');
  }

  clearToastNotification() {
    this.messageService.clear();
  }

  private addMessage(msg: string, summary: string, severity: string, id: string = null, messageTime = null) {
    if (this.msg_dict[msg] === true) {
      return;
    }

    let iMessageTime = 0;
    if (messageTime)
      iMessageTime = messageTime;
    else
      messageTime = this.msg_display_time;

    this.msg_dict[msg] = true;
    setTimeout(() => {
      this.msg_dict[msg] = false;
    }, this.msg_display_time)

    this.messageService.add({
      life: messageTime, closable: true, sticky: false, severity: severity, summary: summary, detail: msg, id: id
    });

  }

  // public showSuccessMsg(msg: string, summary: string = "Success") {
  //   this.messageService.add({ life: 8000, closable: false, sticky: false, severity: 'success', summary: summary, detail: msg });
  // }

  // public showErrorMsg(msg: string, summary: string = "Failure") {
  //   this.messageService.add({ life: 8000, closable: false, sticky: false, severity: 'error', summary: summary, detail: msg });
  // }

  // public showInfoMsg(msg: string, summary: string = "Information") {
  //   this.messageService.add({ life: 8000, closable: false, sticky: false, severity: 'info', summary: summary, detail: msg });
  // }

  resolutionX: number;

  resolutionY: number;

  mainContentWidth: number;

  public getMainContentWidth() {
    return this.mainContentWidth;
  }

  public resetPageLayout() {
    let oldx = this.resolutionX;
    this.mainContentWidth = window.innerWidth;//$("#maincontentdiv_dom").width();
    this.resolutionX = window.innerWidth;
    this.resolutionY = window.innerHeight;
    if (oldx != this.resolutionX) {
      this.closeMainNav();
      this.closeSubNav();
    }
  }

  checkMenuActive() {
    return $("#mainnav_dom").hasClass("ui-sidebar-active")
  }

  toggleMainNav() {
    if ($("#mainnav_dom").hasClass("ui-sidebar-xs")) {
      this.closeMainNav();
    }
    else {
      this.openMainNav(true);
    }
  }

  toggleMainNavSD() {
    if ($("#mainnav_dom").hasClass("ui-sidebar-xs")) {
      this.closeMainNav();
    }
    else {
      this.openMainNavSD();
    }
  }

  openMainNav(isfrombtn: boolean = false) {
    if ($('#mainnav_dom').length > 0) {
      $("#mainnav_dom").addClass("ui-sidebar-xs");
      if (isfrombtn) {
        $('#maincontentdiv_dom').animate({ paddingLeft: "300px" }, 250);
      }
      else {
        $('#maincontentdiv_dom').animate({ paddingLeft: "80px" }, 250);
      }

    }

    // $("#body_dom").addClass("ui-overflow-hidden");
    // $("#navmask_dom").addClass("ui-widget-overlay");
  }



  openMainNavXS() {
    if ($('#mainnav_dom').length > 0) {
      $("#mainnav_dom").addClass("ui-sidebar-active");
      $('#maincontentdiv_dom').animate({ paddingLeft: "80px" }, 250);
    }
  }


  openMainNavSD() {
    // $("#mainnav_dom").addClass("ui-sidebar-active");
    // $("#body_dom").addClass("ui-overflow-hidden");
    // $("#navmask_dom").addClass("ui-widget-overlay");
  }

  closeMainNav() {
    $("#mainnav_dom").removeClass("ui-sidebar-xs");
    $("#navmask_dom").removeClass("ui-widget-overlay");
    $("#body_dom").removeClass("ui-overflow-hidden");
    setTimeout(() => {
      if (this._isLogin) {
        $('#maincontentdiv_dom').animate({ paddingLeft: "80px" }, 250);
      }
    }, 8);

  }

  openSubNav() {
    $("#subnav_dom").addClass("ui-sidebar-active");
    $("#body_dom").addClass("ui-overflow-hidden");
    $("#navmask_dom").addClass("ui-widget-overlay");
  }

  closeSubNav() {
    $("#subnav_dom").removeClass("ui-sidebar-active");
    $("#navmask_dom").removeClass("ui-widget-overlay");
    $("#body_dom").removeClass("ui-overflow-hidden");
  }

  private showtitle: boolean = true;

  doShowTitle() {
    return this.showtitle;
  }

  showTitle() {
    this.showtitle = true;
  }

  hideTitle() {
    this.showtitle = false;
  }

  isScreenSupported() {
    return this.mainContentWidth >= this.minSupportedScreen;
  }

  setPageWidth(width: number) {
    this._width = width;
  }

  getPageWidth(): number {
    return this._width;
  }

  isPasswordSizeSupported() {
    return this.resolutionX > 1200;
  }

  resetView() {
    this.isExpired = false;
    this.pageFault = undefined;
    this.pageready = false;
    this.pagebusycount = 0;
    this.hasUnsavedChanges = false;
    this.isSubPage = false;
    this.isfullapge = false;
    //this.openMainNav();
    this.closeSubNav();
    this.closeDialogs();
  }

  setPageExpired() {
    this.isExpired = true;
    this.forcereset();
  }
  private forcereset() {
    this.pageready = false;
    this.pagebusycount = 0;
    this.hasUnsavedChanges = false;
    this.closeDialogs();
  }

  closeDialogs() {
    $('.modal').modal('hide');
  }

  public pageTitle: string = "";

  public subPageTitle: string = "";

  openMenu(event: any, items: any) {
    this.menutItems = items;
    this.menu.toggle(event);
  }

  getMenutItems() {
    return this.menutItems;
  }

  setMenu(menu: any) {
    this.menu = menu;
  }

  private menu: any;

  private menutItems: any;

  public confirmMessage: string;

  public confirmOptions: any[];

  private confirmDg: DialogComponent;

  Confirm(message: string, options: any[], title: string = "Confirm"): Promise<any> {
    this.confirmMessage = message;
    this.confirmOptions = options;
    this.confirmDg.title = title;
    this.confirmDg.open();
    return new Promise<string>((success, error) => {
      let sub = this.eventservice.observable().subscribe(x => {
        if (x && x.type == "confirmation_dialog") {
          sub.unsubscribe();
          success(x.data);
          this.confirmDg.close();
        }
      });
    });
  }

  setConfirmationDg(confirmDg: DialogComponent) {
    this.confirmDg = confirmDg;
  }

  private meetingdetails: any;

  openTelemedicineSession(meetingUrl: string, meetingMode: string, meetingId: string, password: string, appointmentID: string) {
    this.closeTelemedicineSession();
    this.meetingdetails = {} as any;
    this.meetingdetails.url = meetingUrl;
    this.meetingdetails.mode = meetingMode;
    this.meetingdetails.meetingId = meetingId;
    this.meetingdetails.password = password;
    this.meetingdetails.username = btoa(this.getLoginName());
    this.meetingdetails.appointmentId = appointmentID;
  }

  closeTelemedicineSession() {
    this.meetingdetails = undefined;
  }

  getTelemedicineSession() {
    return this.meetingdetails;
  }

  private pageready: boolean = false;

  setPageReady() {
    this.pageready = true;
  }

  setPageNotReady() {
    this.pageready = false;
  }

  pageHasErrors() {
    return this.pageFault !== undefined || this.isExpired;
  }

  setPageFault(msg: string = "Error occured while processing request.") {
    this.pageFault = msg;
    this.forcereset();
  }

  isPageReady() {
    return this.pageready;
  }

  isPageReadyForRedirect() {
    return this.pageready || this.pageHasErrors();
  }

  pagebusycount: number = 0;

  setPageBusy() {
    this.pagebusycount++;
  }

  setPageNotBusy() {
    this.pagebusycount--;
    if (this.pagebusycount < 0) {
      this.pagebusycount = 0;
    }
  }

  private pagebusyuniquecount: { [id: string]: number; } = {};

  setPageBusyUnique(): string {
    let id = Helper.getID();
    this.pagebusyuniquecount[id] = 1;
    this.setPageBusy();
    return id;
  }

  setPageNotBusyUnique(id: string) {
    this.pagebusyuniquecount[id] = this.pagebusyuniquecount[id] - 1;
    if (this.pagebusyuniquecount[id] == 0) {
      this.setPageNotBusy();
    }
  }
  setEntityChange(val: any) {
    this.entitychange = val;
  }
  isEntityChange(): boolean {
    return this.entitychange;
  }

  setVideoStart(val: any) {
    this.isvideostart = val;
  }
  isVideoStart(): boolean {
    return this.isvideostart;
  }

  isPageFree(): boolean {
    return this.pagebusycount == 0;
  }


  setFullPage(isfullapge: boolean = true) {
    this.isfullapge = isfullapge;
  }

  setEntityScheduleScreenShow(isentityschedulescreen: boolean) {
    this.isentityschedulescreen = isentityschedulescreen
  }
  getEntityScheduleScreenShow() {
    return this.isentityschedulescreen;
  }

  setUserNumberUpdated(isnumberupdate: boolean) {
    this.isusernumberupdate = isnumberupdate;
  }
  getIsUserNumberUpdate() {
    return this.isusernumberupdate
  }

  isFullPage() {
    return this.isfullapge;
  }

  public isSubPage: boolean = false;

  private navigation: PageNavigation = new PageNavigation();

  getPageNavigation() {
    return this.navigation;
  }

}

export class PageNavigation {

  public events: Subject<PageNavEvent> = new Subject<PageNavEvent>();

  titles: PageNavigationTitle[] = [];

  items: PageNavigationItem[] = [];

  OpenedEncounter: string;

  generalitem: PageNavigationItem;

  private selectedPageNavigationSubItem: PageNavigationSubItem;

  private count: number = 0;

  private nongeneralfirstitemindex: number;

  private lastselectedid: string;

  addItem(parent: PageNavigationItem, child: PageNavigationSubItem) {
    let i = ++this.count;
    if (this.items.find(x => x == parent) == undefined) {
      this.items.push(parent);
      parent.lastSubItemIndex = i;
      if (parent.isGeneralItem) {
        this.generalitem = parent;
      }
    }

    if (this.nongeneralfirstitemindex == undefined && !parent.isGeneralItem) {
      this.nongeneralfirstitemindex = i;
    }

    child.parent = parent;
    child.index = i;
    child.isGeneralItem = parent.isGeneralItem;
    parent.items.push(child);
  }

  resetAllNavItems() {
    this.items = [];
    this.generalitem = undefined;
    this.selectedPageNavigationSubItem = undefined;
    this.count = 0;
    this.nongeneralfirstitemindex = undefined;
    this.lastselectedid = undefined;
    this.titles = [];
  }

  getGeneralItem() {
    return this.generalitem;
  }

  start(startid: string, preselectedid: string) {
    let startfrom = preselectedid ? preselectedid : startid;
    this.gotoId(startfrom);
    this.select(startfrom, preselectedid ? true : false);
  }

  select(id: string, preselected: boolean = false) {
    let notfound: boolean = true;
    this.items.forEach(h => {
      let newitem = h.items.find(s => s.id == id);
      if (newitem) {
        notfound = false;
        this.selectedPageNavigationSubItem = newitem;
        this.selectedPageNavigationSubItem.parent.lastSubItemIndex = this.selectedPageNavigationSubItem.index;
        if (!preselected) {
          this.events.next(
            {
              action: "selected",
              type: newitem.id,
              item: newitem
            } as PageNavEvent
          );
        }
      }
    });

    if (notfound && !preselected) {
      this.events.next(
        {
          action: "preselected",
          type: id
        } as PageNavEvent
      );
    }

    this.lastselectedid = id;
  }

  getSelectedItem() {
    return this.selectedPageNavigationSubItem;
  }

  next() {
    this.gotoIndex(this.selectedPageNavigationSubItem.index + 1);
  }

  back() {
    this.gotoIndex(this.selectedPageNavigationSubItem.index - 1);
  }

  gotoIndex(index: number) {
    this.items.forEach(h => {
      let newitem = h.items.find(s => s.index == index);
      if (newitem) {
        this.events.next(
          {
            action: "goto",
            type: newitem.id,
            item: newitem
          } as PageNavEvent
        );
      }
    });
  }

  gotoId(id: string) {
    this.items.forEach(h => {
      let newitem = h.items.find(s => s.id == id);
      if (newitem) {
        this.events.next(
          {
            action: "goto",
            type: newitem.id,
            item: newitem
          } as PageNavEvent
        );
      }
    });
  }

  close() {
    this.events.next(
      {
        action: "close",
        type: this.getSelectedItem().id,
        item: this.getSelectedItem()
      } as PageNavEvent
    );
  }

  saveAndNext() {
    this.events.next(
      {
        action: "save",
        type: this.getSelectedItem().id,
        nextaction: "next",
        item: this.getSelectedItem()
      } as PageNavEvent
    );
  }

  save() {
    this.events.next(
      {
        action: "save",
        type: this.getSelectedItem().id,
        item: this.getSelectedItem()
      } as PageNavEvent
    );
  }

  isLast(): boolean {
    return this.getSelectedItem() && this.getSelectedItem().index == this.count;
  }

  isFirst(): boolean {
    return this.getSelectedItem() && this.getSelectedItem().index == this.nongeneralfirstitemindex;
  }
}

export interface PageNavEvent {
  action: string;
  type: string;
  item: PageNavigationSubItem;
  nextaction: string;
}
export class PageNavigationItem {

  constructor(public title: string, public id: string, public isGeneralItem: boolean) {
  }

  items: PageNavigationSubItem[] = [];

  lastSubItemIndex: number;

  getIconClass(selectedItem: PageNavigationSubItem) {
    return selectedItem && selectedItem.parent == this ? 'fad fa-chevron-circle-right text-success' : 'far fa-chevron-circle-right text-light'
  }

  getDefaultPageNavigationSubItem(): PageNavigationSubItem {
    return this.items[0];
  }
}

export class PageNavigationSubItem {

  visible: boolean = true;

  constructor(public title: string, public id: string) {
  }

  public parent: PageNavigationItem;

  public index: number;

  isGeneralItem: boolean = true;

  getIconClass(selectedItem: PageNavigationSubItem) {
    return selectedItem && selectedItem == this ? 'fad fa-chevron-circle-right text-primary' : 'far fa-chevron-circle-right text-light'
  }
}

export class PageNavigationTitle {
  constructor(public title: string, public text: string) {
  }
}
