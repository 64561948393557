import {Injectable} from '@angular/core';
import {ApiActionSettings, ApiResponse, HttpService} from 'src/app/core/services/http.service';
import {Deceased} from '../models/deceasedrequestresponse';

@Injectable()
export class DeceaseService {

  constructor(private http: HttpService) {
  }

  CanTransferStarted(): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Get("/decease/can-transfer-started", undefined, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  StartTransfer(request: Deceased.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/decease/start-transfer", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  DeceasedMark(request: Deceased.MarkRequest) {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/decease/mark-deceased", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


}
