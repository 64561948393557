import { Injectable } from '@angular/core';
import { EventService } from './event.service';
import { Helper } from '../helpers/helper';

@Injectable()
export class StorageService {

    private cacheversion: string = "None";

    private userid: string = "Anon";

    constructor(eventservice: EventService) {
        this.clearIfNeeded();
        eventservice.observable().subscribe(x => {
            if (x.type == "SIGNED_IN") {
                this.userid = x.data.userID;
            }
        });
    }

    clearIfNeeded() {
        if (localStorage.getItem("cacheversion") != this.cacheversion || localStorage.length > 50) {
            localStorage.clear();
        }
    }

    private getGlobalKey(key: string) {
        return key.concat("_", this.cacheversion)
    }

    private getGlobalExpiryKey(key: string) {
        return key.concat("_expiry_", this.cacheversion)
    }

    private getUserKey(key: string) {
        return key.concat("_", this.cacheversion, "_", this.userid);
    }

    private getUserExpiryKey(key: string) {
        return key.concat("_expiry_", this.cacheversion, "_", this.userid);
    }

    getEndTime(expiry: number) {
        return ((new Date()).getTime() + (expiry * 60 * 1000)).toString();
    }

    isValid(key: string) {
        let endtime = localStorage.getItem(key);
        if (endtime && Helper.IsNumeric(endtime)) {
            return Number(endtime) > (new Date()).getTime();
        }

        return false;
    }

    private setData(key: string, expirykey: string, obj: any, expiry: number): any {
        window.localStorage.setItem(key, JSON.stringify(obj));
        window.localStorage.setItem(expirykey, this.getEndTime(expiry));
    }

    private getData(key: string, expirykey: string): any {
        let data = localStorage.getItem(key);
        let isvalid = this.isValid(expirykey);
        return isvalid && data ? JSON.parse(data) : undefined;
    }

    private removeData(key: string, expirykey: string) {
        localStorage.removeItem(key);
        localStorage.removeItem(expirykey);
    }

    setUserDataWithNoExpiry(key: string, obj: any): any {
        this.setUserData(key, obj, 10000);
    }
    setUserData(key: string, obj: any, expiry: number): any {
        this.setData(this.getUserKey(key), this.getUserExpiryKey(key), obj, expiry);
    }

    getUserData(key: string): any {
        return this.getData(this.getUserKey(key), this.getUserExpiryKey(key));
    }

    removeUserData(key: string) {
        return this.removeData(this.getUserKey(key), this.getUserExpiryKey(key));
    }

    setGlobalDataWithNoExpiry(key: string, obj: any): any {
        this.setGlobalData(key, obj, 10000);
    }

    setGlobalData(key: string, obj: any, expiry: number): any {
        this.setData(this.getGlobalKey(key), this.getGlobalExpiryKey(key), obj, expiry);
    }

    getGlobalData(key: string): any {
        return this.getData(this.getGlobalKey(key), this.getGlobalExpiryKey(key));
    }

    removeGlobalData(key: string) {
        return this.removeData(this.getGlobalKey(key), this.getGlobalExpiryKey(key));
    }
}
