import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PageService } from './page.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurdAdminService implements CanActivate {

  constructor(private _pageService: PageService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this._pageService.isLogin()) {
        if (!this._pageService.IsMainAdminUser()) {
          this.router.navigate(["/auth"]);
          this._pageService.logout();
          this._pageService.showInfoMsg('You are not admin, so you are not allowed to enter into this portal');
          resolve(false);
        }

        resolve(true);
      }
      else {
        resolve(false);
        this._pageService.SetRedirecttUrl(state.url);
        this._pageService.showInfoMsg('You need to "Sign In" to access this feature');
        this.router.navigate(["auth"]);
      }
    });
  }
}
