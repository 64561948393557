import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alertmessage',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {

  constructor() { }

  @Input() message: string;

  @Input() issuccess: boolean;

  ngOnInit() {
  }

  getClass() {
    return this.issuccess === undefined ? "fad fa-info-circle text-primary mr-2" : this.issuccess ? "fad fa-check-circle text-success mr-2" : "fad fa-exclamation-circle text-danger mr-2";
  }
}
