import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DateHelper } from '../helpers/datehelper';
import { PageService } from './page.service';
import { Helper } from '../helpers/helper';
import moment from 'moment-timezone';
import { Router } from '@angular/router';

@Injectable()
export class HttpService {

  private baseurl: string;

  constructor(private http: HttpClient, private pageservice: PageService, private router: Router) {
  }

  private getDefaultSettings(properties?: ApiActionSettings) {
    if (properties) {
      return properties
    }

    return new ApiActionSettings();
  }

  public SetBaseURL(url: string) {
    this.baseurl = url;
    console.log("This is the base URL Set In API Base URL Function ", this.baseurl);
  }

  Get(path: string, querystring?: { [id: string]: string; }, baseurl?: string, properties?: ApiActionSettings): Promise<ApiResponse> {
    let completePath: string = path;
    if (baseurl != undefined) {
      completePath = baseurl.concat(path);
    }
    else {
      completePath = this.baseurl.concat(path);
    }

    properties = this.getDefaultSettings(properties);
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.get(`${completePath}`, { headers: this.getHttpOptions(), params: querystring }).subscribe(
        res => {
          let obj: ApiResponse = res as ApiResponse;
          this.resetStatus(obj);
          if (obj.Status == ReturnType.Success) {
            this.onSuccess(obj, successresolve, properties);
          }
          else {
            if (!properties.pageFaultBypass)
              this.onError(obj, failureresolve, properties);
          }
        },
        err => {
          if (!properties.pageFaultBypass)
            this.onError(err, failureresolve, properties);
        }
      );
    });
  }


  GetPdfFile(path: string, querystring?: { [id: string]: string; }, baseurl?: string, properties?: ApiActionSettings): Promise<Blob> {
    let completePath: string = path;
    if (baseurl != undefined) {
      completePath = baseurl.concat(path);
    }
    else {
      completePath = this.baseurl.concat(path);
    }
    properties = this.getDefaultSettings(properties);
    return new Promise<Blob>((successresolve, failureresolve) => {
      this.http.get(`${completePath}`, { headers: this.getHttpOptions(), params: querystring, responseType: 'blob', observe: 'response' }).subscribe(
        (data: any) => {
          console.log(data);
          let fileName = 'file';
          var contentDisposition = data.headers.getAll('Content-Disposition');
          if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          console.log(fileName);
          successresolve(data.body);
        },
        err => {
          failureresolve(err)
        }
      );
    });
  }




  GetExternal(path?: string, querystring?: { [id: string]: string; }, baseurl?: string, properties?: ApiActionSettings): Promise<any> {
    let completePath: string = path;
    if (baseurl != undefined) {
      completePath = baseurl.concat(path);
    }
    else {
      completePath = this.baseurl.concat(path);
    }
    properties = this.getDefaultSettings(properties);
    return new Promise<any>((successresolve, failureresolve) => {
      this.http.get(`${completePath}`, { params: querystring }).subscribe(
        res => {
          let obj: any = res;
          this.onSuccess(obj, successresolve, properties);
        },
        err => {
          this.onError(err, failureresolve, properties);
        }
      );
    });
  }

  PostMultiPart(path: string, data: any, baseurl?: string, properties?: ApiActionSettings): Promise<ApiResponse> {
    let completePath: string = path;
    if (baseurl != undefined) {
      completePath = baseurl.concat(path);;
    }
    else {
      completePath = this.baseurl.concat(path);
    }
    properties = this.getDefaultSettings(properties);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if (!Helper.checkIsStringEmpty(this.pageservice.getAuthToken())) {
      headers.append('Authorization', "bearer " + this.pageservice.getAuthToken());
    }

    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.post(`${completePath}`, data, {
        headers: headers.append('Authorization', "bearer " + this.pageservice.getAuthToken())
      }).subscribe(res => {
        let obj: ApiResponse = res as ApiResponse;
        this.resetStatus(obj);
        if (obj.Status == ReturnType.Success) {
          this.onSuccess(obj, successresolve, properties);
        }
        else {
          this.onError(obj, failureresolve, properties);
        }
      },
        err => {
          this.onError(err, failureresolve, properties);
        });
    });
  }

  Post(path: string, data: any, baseurl?: string, properties?: ApiActionSettings): Promise<ApiResponse> {
    let completePath: string = path;
    if (baseurl != undefined) {
      completePath = baseurl.concat(path);;
    }
    else {
      completePath = this.baseurl.concat(path);
    }
    properties = this.getDefaultSettings(properties);
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.post(`${completePath}`, data, {
        headers: this.getHttpOptions()
      }).subscribe(
        res => {
          let obj: ApiResponse = res as ApiResponse;
          this.resetStatus(obj);
          if (obj.Status == ReturnType.Success) {
            this.onSuccess(obj, successresolve, properties);
          }
          else {
            this.onError(obj, failureresolve, properties);
          }
        },
        err => {
          this.onError(err, failureresolve, properties);
        }
      );
    });
  }

  PostExternal(path: string, data: any, baseurl?: string, properties?: ApiActionSettings): Promise<any> {
    let completePath: string = path;
    if (baseurl != undefined) {
      completePath = baseurl;
    }
    else {
      completePath = this.baseurl.concat(path);
    }
    properties = this.getDefaultSettings(properties);
    return new Promise<any>((successresolve, failureresolve) => {
      this.http.post(`${completePath}`, data).subscribe(
        res => {
          let obj: any = res;
          this.onSuccess(obj, successresolve, properties);
        },
        err => {
          this.onError(err, failureresolve, properties);
        }
      );
    });
  }

  errorHandler(err) {
    return "";
  }

  getHttpOptions() {
    let headers = new HttpHeaders();
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append("Access-Control-Allow-Credentials", "true");
    headers = headers.append('Access-Control-Allow-Headers', '*');
    headers = headers.append('Content-Type', 'application/json; charset=UTF-8');
    headers = headers.append('Accept', 'application/json');
    //headers = headers.append('Date', DateHelper.GetLocalTodayStartUtc().toISOString());
    headers = headers.append('Cache-Control', 'no-cache, private');
    headers = headers.append('Timezone', moment.tz.guess());
    // headers = headers.append('ApplicationType', 'Web');
    //headers = headers.append('ClientId', 'b7b3675e-5fe7-e259-9bf8-c2464d56a4ba');
    if (!Helper.checkIsStringEmpty(this.pageservice.getAuthToken())) {
      headers = headers.append('Authorization', "bearer " + this.pageservice.getAuthToken());
      headers = headers.append('platformos', "web");
    }

    return headers;
  }

  private onError(err: any, failureresolve: (reason?: any) => void, properties?: ApiActionSettings) {
    if (err == undefined || (err && !err.IsAppResponse) || err.Status == ReturnType.Error) {
      this.pageservice.setPageFault();
      return;
    }
    else if (err.Status == ReturnType.UnauthorizedAccess) {
      const token = this.pageservice.getAuthToken();
      this.pageservice.setPageReady();
      this.pageservice.setPageExpired();
      this.pageservice.logout();
      if (token) {
        let decodedToken: any = this.pageservice.getDecodedToeknInfo(token);
        if (decodedToken && decodedToken.exp) {
          const currentTime = Math.floor(Date.now() / 1000);
          if (decodedToken.exp < currentTime) {
            this.pageservice.showErrorMsg("Session timed-out, please re-login.");
          }
        }
      }
      this.router.navigate(["auth"]);
      // this.pageservice.route("/auth");
      return;
    }

    if (err.error && err.error.IsAppResponse) {
      err = err.error;
    }

    failureresolve(err);
    if (properties.showFailureMessage) {
      if (err.IsAppResponse) {
        if (err.Messages) {
          err.Messages.forEach(x => this.pageservice.showErrorMsg(x));
        }
        else if (err.Message) {
          this.pageservice.showErrorMsg(err.Message);
        }
      }
      else {
        this.pageservice.showErrorMsg(err.Message);
      }
    }
  }

  private onSuccess(obj: any, successresolve: (value?: {} | PromiseLike<{}>) => void, properties: ApiActionSettings) {
    successresolve(obj);
    if (properties.showSuccessMessage) {
      if (obj.IsAppResponse) {
        if (obj.Messages) {
          obj.Messages.forEach(x => this.pageservice.showSuccessMsg(x));
        }
      }
    }
  }

  private resetStatus(obj: any) {
    if (obj.Status == "Success") {
      obj.Status = ReturnType.Success;
    }
    else if (obj.Status == "Failure") {
      obj.Status = ReturnType.Failure;
    }
    else if (obj.Status == "ValidationError") {
      obj.Status = ReturnType.ValidationError;
    }
    else if (obj.Status == "UnauthorizedAccess") {
      obj.Status = ReturnType.UnauthorizedAccess
    }
    else if (obj.Status == "Error") {
      obj.Status = ReturnType.Error;
    }

    return obj;
  }
}

export class ApiActionSettings {
  showSuccessMessage: boolean = true;
  showFailureMessage: boolean = true;
  pageFaultBypass: boolean = false;
}

export interface ApiResponse {
  Status: ReturnType;

  StatusCode: number;

  IsAppResponse: boolean;

  ReturnCode: string;

  Message: string;

  Data: any;
}

export interface ApiResponseGeneric<T> {
  Status: ReturnType;

  StatusCode: number;

  IsAppResponse: boolean;

  ReturnCode: string;

  Message: string;

  Data: T;
}

export enum ReturnType {
  Success,
  Failure,
  ValidationError,
  UnauthorizedAccess,
  Error
}
