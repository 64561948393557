import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PageService } from '../../services/page.service';
import { UserService } from 'src/app/logic/shared/services/user.service';
import { EventService } from '../../services/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-selector',
  templateUrl: './app-user-selector.component.html',
  styleUrls: ['./app-user-selector.component.scss']
})
export class AppUserSelectorComponent implements OnInit, OnDestroy {

  @Input() isDropdown: boolean = true;
  searchText: string = "";

  @Input() mode: string = "prefilledlist";

  @Input() view: string = "dropdown";

  @Input() right: string;

  @Input() items: any[];

  @Input() selected: any;

  @Output() onSearched = new EventEmitter<any[]>();

  @Output() startSearch = new EventEmitter();

  @Output() endSearch = new EventEmitter();
  @Input() colorSelection: boolean = true;
  selectedID: any;
  modeChange: any;
  subscription: Subscription;

  constructor(private pageservice: PageService, private userServ: UserService, private eventServ: EventService) {
    this.selectedID = null;

    let tEntity = this.pageservice.getSelectedUser();
    if (tEntity) {
      this.selected = tEntity;
      this.selectedID = tEntity.userid;
    }

    this.subscription = this.eventServ.observable().subscribe(data => {
      if (data.type == 'Profile_Changed') {
        this.selected = this.getUser(data.data.userid);
        this.selectedID = data.data.userid;
      }
    })
  }

  getClass(item: any) {
    if (!this.colorSelection && item.accounttype == 'Normal Account') {
      return '';
    }
    if (item.userid == this.selected?.userid) {
      return 'selectedUser'
    }
    //(colorSelection && item.accounttype == 'Normal Account') || item.accounttype != 'Normal Account') && item.userid == selected?.userid ? 'selectedUser':''
    return '';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngDoCheck() {
    if (this.selectedID != null && this.items != null) {
      this.selected = this.getUser(this.selectedID);
      this.selectedID = null;
    }
  }
  ngOnInit(): void {
    this.GetSelectedUser();
  }


  GetSelectedUser() {
    this.items
    let tData = this.pageservice.getLoggedInUserProfile();
    if (tData) {
      if (this.items) {
        if (tData.user.adminid == null) {
          this.selected = this.getUser(tData.user.id);
        }
        else {
          this.selected = this.getUser(tData.user.adminid);
        }
      }
      else {
        if (tData.user.adminid == null) {
          this.selectedID = tData.user.id;
        }
        else {
          this.selectedID = tData.user.adminid;
        }
      }
    }
    //this.eventServ.publish2('Profile_Changed', this.selected);
  }

  getUser(id: number) {
    return this.items.find(data => {
      return data.userid == id;
    })
  }

  getLoginUserDisplayID() {
    return;
  }

  clearSearch() {
    this.searchText = "";
  }

  isbusy: boolean = false;
  onChange(item: any) {
    if (item.accounttype == 'Normal Account' && !this.pageservice.GetUserPurchases()) {
      console.log("normal");
      this.pageservice.setPaymentScreenShow(true);
      this.pageservice.setPageNotReady();
      // this.pageservice.setPageBusy();
      this.pageservice.setSelectedUser(item);
      this.eventServ.publish2('Profile_Changed', item);

      this.userServ.ChangeUserProfile({ userId: item.userid }).then(res => {

        // $("#pills-profile").removeClass("show");
        // $("#pills-profile").removeClass("active");

        // $("#pills-home").removeClass("show");
        // $("#pills-home").removeClass("active");

        this.pageservice.tryLogin({ isLogin: true, Data: res.Data }, true);
        this.selected = this.getUser(item.userid);
        this.selectedID = item.userid;
        this.eventServ.publish3("Profile_Successfully_Changed");

        // this.pageservice.changeProfileFlag = true;
      }).catch(err => {
      }).finally(() => {

        // this.pageservice.setPageNotBusy();
      })
      setTimeout(() => {
        this.SwitchProfileButtonShow();
      }, 1000);
    }
    else {
      console.log("not normal");
      this.pageservice.setPaymentScreenShow(false);
      this.pageservice.setPageNotReady();
      // this.pageservice.setPageBusy();
      this.pageservice.setSelectedUser(item);
      this.eventServ.publish2('Profile_Changed', item);

      this.userServ.ChangeUserProfile({ userId: item.userid }).then(res => {

        console.log(res.Data);
        this.pageservice.tryLogin({ isLogin: true, Data: res.Data }, true);
        this.selected = this.getUser(item.userid);
        this.selectedID = item.userid;

        this.eventServ.publish3("Profile_Successfully_Changed");
        setTimeout(() => {
          //this.SwitchProfileButtonShow();
        }, 1000);
      }).catch(err => {
      }).finally(() => {

        // this.pageservice.setPageNotBusy();
      })
    }

    //this.onUserSelect.emit(item.value);
  }
  SwitchProfileButtonShow() {
    return this.pageservice.changeProfileFlag = true;
  }


  GetProfilePic(item: any) {
    if (item?.fulluser) {
      if (item?.fulluser.profilepicture == null) {
        return "/assets/images/profile-icon.png";
      }
      else {
        let userprofilepic = item.fulluser.profilepicture + '?rnd=' + this.pageservice.getRandom();
        return userprofilepic;
      }
    }
  }

}
