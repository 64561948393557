import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentViewComponent } from './content-view.component';
import { GenericMessageModule } from '../generic-message/generic-message.module';
import { LoaderModule } from '../loader/loader.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { ProgressBarModule } from 'primeng/progressbar'
import { ResourceUsageBarModule } from 'src/app/logic/shared/components/resource-usage-bar/resource-usage-bar.module';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppUserSelectorModule } from '../app-user-selector/app-user-selector.module';

@NgModule({
  declarations: [ContentViewComponent],
  imports: [
    CommonModule,
    GenericMessageModule,
    LoaderModule,
    IconButtonModule,
    ProgressBarModule,
    ResourceUsageBarModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    AppUserSelectorModule
  ],
  providers: [ContentViewComponent],
  exports: [ContentViewComponent]
})
export class ContentViewModule { }
