import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncateTextPipe } from './truncate-text.pipe';
import { ReplaceLineBreaksPipe } from './replace-line-breaks.pipe';
import { MbpipePipe } from './mbpipe.pipe';
import { UrlencodePipe } from './urlencode.pipe';
import { ReplceiconPipe } from './replceicon.pipe';
import { FilterPipe } from '../directive/filter.pipe';
import { SanitizehtmlPipe } from './sanitizehtml.pipe';
import { LabelvaluedisplayPipe } from './labelvaluedisplay.pipe';
import { TildatobrPipe } from './tildatobr.pipe';
import { RemoveversionsfromtemplatePipe } from './removeversionsfromtemplate.pipe';
import { FormlabelvaluedsdisplayPipe } from './formlabelvaluedsdisplay.pipe';
import { FilterDataPipe } from './filter-data.pipe';
import { BooleanPipe } from './boolean.pipe';
import { FilterRegisterdUser } from './filter-registerd-user.pipe';



@NgModule({
  declarations: [FilterDataPipe, TruncateTextPipe, ReplaceLineBreaksPipe, MbpipePipe, UrlencodePipe, ReplceiconPipe, FilterPipe, SanitizehtmlPipe, LabelvaluedisplayPipe, TildatobrPipe, RemoveversionsfromtemplatePipe, FormlabelvaluedsdisplayPipe, BooleanPipe, FilterRegisterdUser],
  imports: [
    CommonModule
  ],
  exports: [FilterDataPipe, TruncateTextPipe, ReplaceLineBreaksPipe, MbpipePipe, UrlencodePipe, ReplceiconPipe, FilterPipe, FormlabelvaluedsdisplayPipe, SanitizehtmlPipe, LabelvaluedisplayPipe, TildatobrPipe, RemoveversionsfromtemplatePipe, BooleanPipe, FilterRegisterdUser]
})
export class PipeModule { }
