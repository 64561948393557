import { Injectable } from '@angular/core';
import { EventService } from './event.service';

@Injectable()
export class CacheService {

  constructor(eventservice: EventService) {
    eventservice.observable().subscribe(x => {
      if (x.type == "SIGNED_OUT" || x.type == "SIGNING_IN") {
        this.resetService();
      }
    });
  }

  private resetService() {
    let tKey = sessionStorage.getItem("profilelists");
    let selectedGroup = sessionStorage.getItem("Virtual_Valut_Selected_Group");
    let selectedFolder = sessionStorage.getItem("Virtual_Valut_Selected_Folder");
    let selectedScreen = sessionStorage.getItem("Virtual_Vault_Screen_Name");
    let tAdminaceesKey = sessionStorage.getItem("Admin_Access_Details");
    let tModuleID = sessionStorage.getItem("ModuleID");
    sessionStorage.clear();
    if (tKey) {
      sessionStorage.setItem("profilelists", tKey);
    }
    else if (tAdminaceesKey) {
      sessionStorage.setItem("Admin_Access_Details", tAdminaceesKey);
    }
    if (tModuleID) {
      sessionStorage.setItem("ModuleID", tModuleID);
    }
    if (selectedGroup) {
      sessionStorage.setItem("Virtual_Valut_Selected_Group", selectedGroup);
    }
    if (selectedFolder) {
      sessionStorage.setItem("Virtual_Valut_Selected_Folder", selectedFolder);
    }
    if (selectedScreen) {
      sessionStorage.setItem("Virtual_Vault_Screen_Name", selectedScreen);
    }
  }

  setCacheData(key: string, obj: any): any {
    window.sessionStorage.setItem(key, JSON.stringify(obj));
  }

  getCacheData(key: string): any {
    let data = sessionStorage.getItem(key);
    if (data && data != "undefined")
      return data ? JSON.parse(data) : undefined;
    else
      return undefined;
  }

  removeCacheData(key: string) {
    sessionStorage.removeItem(key);
  }
}
