import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedBaseView } from '../../models/baseview';
import { DashboardService, ResourceUsageBar } from '../../services/dashboard.service';
import { EventService } from 'src/app/core/services/event.service';
import { PageService } from 'src/app/core/services/page.service';
import { Subscription } from 'rxjs';
import { ApiResponseGeneric } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-resource-usage-bar',
  templateUrl: './resource-usage-bar.component.html',
  styleUrls: ['./resource-usage-bar.component.scss']
})
export class ResourceUsageBarComponent extends SharedBaseView implements OnInit, OnDestroy {
  subscription: Subscription;
  resources: ApiResponseGeneric<ResourceUsageBar> = {} as ApiResponseGeneric<ResourceUsageBar>;
  isLoaded: boolean = false;

  constructor(private eventService: EventService,
    private dashboardService: DashboardService,
    private pageService: PageService) {
      super(pageService);
      this.setPageWidth(3);
      this.setPageReady();
      this.refresh();
      this.subscription = this.eventService.observable().subscribe(data=>{
        if (data.type == "New_File_Uploaded") {
          this.refresh();
        }
      })
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  buyMoreSpace() {
    this.route("payments");
  }

  refresh() {
    this.dashboardService.ResourceUsabeBar().then(data=>{
      this.resources=data;
    })
    .finally(()=>{

      this.isLoaded=true;
    })
  }
}
