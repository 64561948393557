import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/services/event.service';
import { PageService } from 'src/app/core/services/page.service';
import { MyLifeADBaseView } from '../provider/models/MYLifeADBaseView';
import { timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent extends MyLifeADBaseView implements OnInit {
  routerEventSubscription: Subscription;
  routeURL: any
  constructor(private _pageService: PageService,
    private router: Router,
    private eventService: EventService) {
    super(_pageService);
    this.getPageService().showResourceUsageBar = false;

    this.routerEventSubscription = this.router.events.subscribe(data => {
      if (data instanceof NavigationError) {
        let url = data.url.substring(1)
      } else if (data instanceof NavigationEnd) {
        this.getPageService().showResourceUsageBar = false;
        let tempURL = data.url.split('/');
        if (tempURL.length > 1) {
          this.routeURL = tempURL[1];
        }
        this.setRouteModule();
        if (this.getPageService().isLogin())
          this.showLeftNav();

      } else if (data instanceof RouteConfigLoadStart) {
        this.setPageBusy();
      } else if (data instanceof RouteConfigLoadEnd) {
        this.setPageNotBusy();
      }
    })
  }

  ngOnInit(): void {
  }

  getNavItems(): any[] {
    let tObject =
      [
        {
          "title": "Dashboard",
          "value": 1,
          "icon": "fal fa-tachometer-slow",
          "route": "dashboard",
          "twofa": 0,
          "dashboardstyle": null,
          "isvisible": true,
          "walkthrough": ""
        },
        {
          "title": "Companies",
          "value": 15,
          "icon": "fal fa-building",
          "route": "companies",
          "twofa": 0,
          "dashboardstyle": "adminsidebar text-white rounded",
          "isvisible": true,
          "walkthrough": ""
        },
        {
          "title": "Individual Discount",
          "value": 16,
          "icon": "fal fa-user-tag",
          "route": "discounts",
          "twofa": 0,
          "dashboardstyle": "dashbox10 text-white rounded",
          "isvisible": true,
          "walkthrough": ""
        },
        {
          "title": "Group Discount",
          "value": 17,
          "icon": "fal fa-badge-percent",
          "route": "groupdiscounts",
          "twofa": 0,
          "dashboardstyle": "dashbox10 text-white rounded",
          "isvisible": true,
          "walkthrough": ""
        },
        {
          "title": "Listed Users",
          "value": 18,
          "icon": "fal fa-users",
          "route": "users",
          "twofa": 0,
          "dashboardstyle": "dashbox10 text-white rounded",
          "isvisible": true,
          "walkthrough": ""
        },
        // {
        //   "title": "Terminal Illness",
        //   "value": 19,
        //   "icon": "fal fa-info-circle",
        //   "route": "cancermonth",
        //   "twofa": 0,
        //   "dashboardstyle": "dashbox10 text-white rounded",
        //   "isvisible": true,
        //   "walkthrough": ""
        // },

      ]

    this.getPageService().setLoginNavItems(tObject);

    return tObject;
  }

  onNavSelected(item: any) {
    if (item != 'dashboard') {
      item += '/list'
      this.route(item);
    }
    else {
      this.route(item);
    }
  }

  setRouteModule() {
    let navItem = this.getNavItems().find(data => {
      return data.route == this.routeURL || data.route == this.routeURL + "/list";
    })
    if (navItem) {
      this.eventService.publish2("MODULE_ID", navItem.value);
      this.setModuleId(navItem.value);
      this.setModuleName(navItem.title);
      this.setTwoFARequired(navItem.twofa);
      this.getPageService().setWalkThroughUrl(navItem.walkthrough);
      this.setDashboardStyle(navItem.dashboardstyle);
    } else {
      this.setModuleId(0);
      this.setModuleName("");
      this.setDashboardStyle("");
      this.setTwoFARequired(false);
    }
  }
}
