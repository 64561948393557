import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { HttpService } from 'src/app/core/services/http.service';
import { EventService } from '../../../core/services/event.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  static AppConfig: IAppConfig;
  static notLive: number = 0;
  static notLiveMessage: string;

  constructor(public http: HttpClient,
    private httpservice: HttpService, private eventServ: EventService) {
  }

  load() {
    const jsonFile = environment.env;
    // const jsonFile = environment.apiUrl + '/configuration';
    //const jsonFile = '../../../../assets/appsettings.json';
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((initdata: any) => {
        if (environment.enableOnlyForDevelopment)
          initdata.Data.SettingsURL = "http://localhost/api";         //Enable only for development, Only for development 
        environment.apiUrl = initdata.Data.SettingsURL
        console.log("Going to set environment API URL: ", environment.apiUrl);
        this.httpservice.SetBaseURL(initdata.Data.SettingsURL);
        this.http.get(initdata.Data.SettingsURL + '/configuration').toPromise().then((response: any) => {
          ConfigService.AppConfig = response.Data;
          this.eventServ.publish3("CONFIG_LOADED");
          if (initdata.Data.MaintenanceMode.Enabled) {
            ConfigService.notLive = 1;
            ConfigService.notLiveMessage = initdata.Data.MaintenanceMode.Message;
          } else if (environment.appVersion < initdata.Data.LatestVersion) {
            ConfigService.notLive = 2;
            ConfigService.notLiveMessage = initdata.Data.UpgradeMessage;
          }
          resolve();
        });
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }
}


export interface IAppConfig {
  SettingsVersion: number;
  Settings: any;
}
