import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  constructor() { }

  @Input() title: string;

  @Input() iconClass: string;

  @Input() cssClass: string;

  @Input() isBusy: boolean = false;

  @Input() isDisabled: boolean = false;

  @Output() clicked = new EventEmitter<any>();

  ngOnInit() {
  }

  onClick(event: any) {
    this.clicked.emit(event);
  }
}
