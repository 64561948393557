import { SharedBaseView } from 'src/app/logic/shared/models/baseview';
import { PageService } from 'src/app/core/services/page.service';

export class MyLifeADBaseView extends SharedBaseView {
  constructor(pageservice: PageService) {
    super(pageservice);
  }

  setModuleId(id: number) {
    console.log("******** Module ID", id);
    this.getPageService().setModuleId(id);
  }


  getDashboard() {
    return this.getPageService().getDashboardData();
  }


  setDashboard(item: any) {
    this.getPageService().setDahboardData(item);
  }


  getModuleId() {
    return this.getPageService().getModuleId();
  }

  setModuleName(name: string) {
    this.getPageService().setModuleName(name);
  }

  getModuleName() {
    return this.getPageService().getModuleName();
  }

  setDashboardStyle(input: string) {
    return this.getPageService().setDashboardStyle(input);
  }

  getDashboardStyle() {
    return this.getPageService().getDashboardStyle();
  }

  setTwoFARequired(twofa: boolean) {
    return this.getPageService().setTwoFARequired(twofa);
  }

  setMenuItem(item: string) {
    this.getPageService().setMenuItem(item);
  }
  getMenuItem() {
    return this.getPageService().getMenuItem();
  }
}
