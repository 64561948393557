import { OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { DateHelper } from '../helpers/datehelper';
import { PageService } from '../services/page.service';
import { Helper } from '../helpers/helper';
import { AppValidator } from './validators/validators';
import { Directive } from '@angular/core';

@Directive()
export class BaseView implements OnInit, OnDestroy, AfterViewInit {
    ngOnDestroy(): void {
        if (this.subsriptions) {
            this.subsriptions.forEach(x => x.unsubscribe());
        }
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {

    }

    getTimeZoneText() {
        return this.getDateHelper().GetLocalDateTimeWithZone();
    }


    validator: AppValidator;

    private pageservice: PageService;

    getColumnTwoClass(mode: number = undefined) {
        return this.pageservice.resolutionX > 960 ? "d-flex w-50" : "d-flex w-100";
    }

    getColumnOneClass(mode: number = undefined) {
        return this.pageservice.resolutionX > 960 ? "w-50 h-100 d-flex justify-content-center align-items-end" : "d-none";
    }

    constructor(pageservice: PageService) {
        this.pageservice = pageservice;
        this.pageservice.subNavText = undefined;
        this.pageservice.resetView();
        this.setMinSupportedScreen(1024);
        this.showLeftNav();
        this.setPageWidth(2);
        //this.setPageTitle(undefined);
        this.showTitle();
        this.getPageNavigation().resetAllNavItems();
    }

    setEntityScheduleScreenShow(isentityschedulescreen: boolean) {
        this.pageservice.setEntityScheduleScreenShow(isentityschedulescreen);
    }
    setUserNumberUpdated(isnumberupdate: boolean) {
        this.pageservice.setUserNumberUpdated(isnumberupdate);
    }
    setFullPage(isfullapge: boolean = true) {
        this.pageservice.setFullPage(isfullapge);
    }

    isFullPage() {
        return this.pageservice.isFullPage();
    }

    showTitle() {
        this.pageservice.showTitle();
    }

    hideTitle() {
        this.pageservice.hideTitle();
    }

    setMinSupportedScreen(minSupportedScreen: number) {
        this.pageservice.minSupportedScreen = minSupportedScreen;
    }

    hasUnsavedChanges(unsaved: boolean) {
        this.pageservice.hasUnsavedChanges = unsaved;
    }

    setSubNavText(str: string) {
        this.pageservice.subNavText = str;
    }

    route(route: string) {
        this.pageservice.navservice.route(route);
        return false;
    }

    testroute(route: string) {
        this.pageservice.navservice.testroute(route);
        return false;
    }
    routeParams(route: string, params: string[]) {
        this.pageservice.navservice.routeParams(route, params);
        return false;
    }

    routeUnique(route: string, data: any = undefined) {
        this.pageservice.navservice.routeUnique(route, data);
        return false;
    }

    getParams() {
        return this.pageservice.navservice.getParams();
    }

    getPageService(): PageService {
        return this.pageservice;
    }

    setNewtworkCallBypass(callbypass: boolean) {
        this.pageservice.setNewtworkCallBypass(callbypass);
    }

    getNetworkCallByPass() {
        return this.pageservice.getNetworkCallByPass();
    }

    getUniqueParams(keyid: string) {
        return this.pageservice.navservice.getUniqueParams(keyid);
    }

    isLastRoute(route: string, keyid: string) {
        return this.pageservice.navservice.isLastRoute(route, keyid);
    }

    setSelectedNav(nav: string) {
        this.pageservice.navservice.selectedNav = nav;
    }

    getSelectedNav() {
        return this.pageservice.navservice.selectedNav;
    }

    setPageExpired() {
        this.pageservice.setPageExpired();
    }

    public setSocialProviderName(socialprovider: string) {
        this.pageservice.socialProviderName = socialprovider;
    }

    public getSocialProvider() {
        return this.pageservice.socialProviderName;
    }


    public setSocialProviderToken(socialprovidertoken: string) {
        this.pageservice.socialProvidertoken = socialprovidertoken;
    }

    public getSocialProviderToken() {
        return this.pageservice.socialProvidertoken;
    }

    setPageFault(msg: string = undefined) {
        this.pageservice.setPageFault(msg);
    }

    public hideLeftNav() {
        this.pageservice.hideLeftNav();
    }

    public hideLeftNavBar() {
        this.pageservice.hideLeftNavBar();
    }

    public showLeftNav(colsize: number = 2) {
        this.pageservice.showLeftNav(colsize);
    }

    public getLeftNavSize(): number {
        return this.pageservice.getLeftNavSize();
    }

    public showSuccessMsg(msg: string, summary: string = "Success") {
        this.pageservice.showSuccessMsg(msg, summary);
    }

    public showErrorMsg(msg: string, summary: string = "Failure", severity: string = '', id: string = 'toast-error-msg') {
        this.pageservice.showErrorMsg(msg, summary, severity, id);
    }

    public showInfoMsg(msg: string, summary: string = "Information") {
        this.pageservice.showInfoMsg(msg, summary);
    }

    public isLogin(): boolean {
        return this.pageservice.isLogin();
    }

    public signinRoute(): string {
        return "/auth";
    }

    signupRoute(): string {
        return undefined;
    }

    getDateHelper(): any {
        return DateHelper;
    }

    closeSubNav() {
        this.pageservice.closeSubNav();
    }

    getHelper() {
        return Helper;
    }

    setPageWidth(width: number) {
        this.pageservice.setPageWidth(width);
    }

    openMenu(event: any, items: any) {
        this.pageservice.openMenu(event, items);
    }

    private subsriptions: any[];

    public addSubsription(subs: any) {
        if (!this.subsriptions) {
            this.subsriptions = [];
        }

        this.subsriptions.push(subs);
    }

    setPageReady() {
        this.pageservice.setPageReady();
    }

    setPageNotReady() {
        this.pageservice.setPageNotReady();
    }

    isPageReady() {
        return this.pageservice.isPageReady();
    }

    setPageBusy() {
        this.pageservice.setPageBusy();
    }

    setPageNotBusy() {
        this.pageservice.setPageNotBusy();
    }

    setPageBusyUnique(): string {
        return this.pageservice.setPageBusyUnique();
    }

    setPageNotBusyUnique(id: string) {
        this.pageservice.setPageNotBusyUnique(id);
    }

    isPageFree() {
        return this.pageservice.isPageFree();
    }

    setPageTitle(title: string) {
        this.pageservice.pageTitle = title;
    }

    setIsSubPage() {
        this.pageservice.isSubPage = true;
    }

    getPageNavigation() {
        return this.getPageService().getPageNavigation();
    }

    getAdminStatus() {
        return this.pageservice.getAdminStatus();
    }

    getisAdminUser() {
        return this.pageservice.IsAdminUser();
    }

}
