import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppUserSelectorComponent } from './app-user-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { UserService } from 'src/app/logic/shared/services/user.service';


@NgModule({
  declarations: [AppUserSelectorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule
  ],
  exports: [AppUserSelectorComponent],
  providers: [UserService]
})
export class AppUserSelectorModule { }
