import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PageService } from './page.service';
import Swal from 'sweetalert2';
declare var recorder;

@Injectable()
export class PendingProcessingGuardService implements CanDeactivate<any> {
  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    if (nextState.url == '/accountprocess')
      return true;

    if (this.pageservice.isEntityChange() || this.pageservice.isVideoStart()) {
      this.pageservice.setPendingGaurdRoute(nextState.url);
      Swal.fire({
        title: "Warning",
        text: "All unsaved work will be lost",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: "Close",
        confirmButtonText: "Ok"
      }).then(data => {
        if (data.isConfirmed) {
          if (this.pageservice.isVideoStart()) {
            recorder.camera.stop();
            recorder.destroy();
            recorder = null;
            this.pageservice.setVideoStart(false);
          }
          this.pageservice.setEntityChange(false);
          this.pageservice.routePendingGaurd();
          this.pageservice.navservice.selectedNav = this.getURLFirstPart(nextState.url);
        }
      })

      this.pageservice.navservice.selectedNav = this.getURLFirstPart(currentState.url);
      return false;
    }
    return this.pageservice.isPageFree() && this.pageservice.isPageReadyForRedirect();
  }

  getURLFirstPart(input: string) {
    return input.split('/')[1];
  }

  constructor(private pageservice: PageService) { }
}
