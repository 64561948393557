<div *ngIf="!isVertical" class="row my-3">
    <div class="col-12">
        <div class="d-flex">
            <div class="text-center">
                <span style="font-size: 5rem;" [ngClass]="icon"></span>
            </div>
            <div class="ml-3">
                <h2 class="text-strong">{{title}}</h2>
                <div class="gnric_maxw" [innerHTML]="message"></div>
                <app-button (clicked)="clicked.emit()" *ngIf="buttonText" cssClass="mt-2 btn btn-outline-primary btn-sm" [title]="buttonText"></app-button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isVertical" class="row my-3">
    <div class="col-12 text-center gnric_mtop">
        <div>
            <div>
                <span style=" font-size: 5rem !important; color: #999 !important;" class="gnric_fsizecolor" [ngClass]="icon"></span>
            </div>
            <div class="mt-3">
                <h2 class="text-strong gnric_colorg6">{{title}}</h2>
                <div class="gnric_colorg9"  [innerHTML]="message"></div>
                <!-- <div style="max-width:300px">{{message}}</div> -->
                <app-button (clicked)="clicked.emit()" *ngIf="buttonText" cssClass="mt-2 btn btn-outline-primary btn-sm" [title]="buttonText"></app-button>
            </div>
        </div>
    </div>
</div>