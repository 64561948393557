import {Injectable} from '@angular/core';
import {ManageEntityRequest} from '../models/manageentityrequest';

@Injectable()
export class DeepcloneService {
  cloneEntity: ManageEntityRequest.Request = {} as ManageEntityRequest.Request

  constructor() {
  }

  SetdeepClone(value: ManageEntityRequest.Request) {
    this.cloneEntity = JSON.parse(JSON.stringify(value));
  }

  getDeepClone() {
    return JSON.parse(JSON.stringify(this.cloneEntity));
  }
}
