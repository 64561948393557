<div class="col-12 mt-4 pl-0 rusageb_minh">
  <div class="float-left pr-3" *ngIf="isLoaded">
    {{resources.Data?.text}}
  </div>
  <div class="float-left pt-1 rusageb_wdth" *ngIf="isLoaded">
      <div class="progress1">
          <div class="progress-bar" type="success" role="progressbar" [ngStyle]="{'width.px': resources.Data?.percentage}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
  </div>
  <div class="float-left" *ngIf="isLoaded">
      <a  class="pl-3 pt-0" (click)="buyMoreSpace()">Buy more!</a>
  </div>
</div>

