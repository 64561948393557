<ng-container *ngIf="mode == 'prefilledlist' && view == 'dropdown' && items && items.length > 0 && (items.length > 1) && !isDropdown">
    <p-dropdown [options]="items" optionLabel="useremail" [(ngModel)]="selected" [style]="{'width':'300px', 'padding-top':'0px' , 'border' : '1px solid #e5e5e5;'}" (click)="items.length > 1 ? onChange($event) : false">
        <ng-template let-item pTemplate="selectedItem">
            <span class="truncate-text text-sm text-strong ml-2">{{item.value.useremail}}</span>
            <span class="truncate-text d-block text-xsm ml-2">{{item.value.accounttype}}</span>
        </ng-template>
        <ng-template let-i pTemplate="item">
            <span class="truncate-text text-sm text-strong">{{i.value.useremail}}</span>
            <span class="truncate-text d-block text-xsm">{{i.value.accounttype}}</span>
        </ng-template>
    </p-dropdown>
</ng-container>


<ng-container *ngIf="items && items.length > 0 && (items.length > 1) && isDropdown">
    <div class="contnt_p15">
    <div class="row border-top px-3 py-2" *ngFor="let item of items" [ngClass]="getClass(item)">
        <a (click)="items.length > 1 ? onChange(item) : false" class="d-flex align-items-center">
            <div class="flex-shrink-0 adminusericon">
                <!-- <i class="fal fa-user-plus"></i> -->
                <img width="50" height="50" class="rounded-circle profile-picture" [src]="item.fulluser.profilepicture">
            </div>
            <div class="media-body pl-3 align-self-center">
                <h6 class="font-weight-bold mb-1 text-truncate" style="width: 270px;">{{item.fulluser.fullname}}</h6>
                <h6 class="mb-1 text-truncate" style="width: 270px;">{{item.useremail}}</h6>
                <p class="m-0" style="color: #888;">{{item.accounttype}}</p>
            </div>
        </a>
    </div>
    </div>
</ng-container>