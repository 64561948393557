<div class="col-12 bg-white p-0 contnt_minh3" style="position: absolute; width: 100%; z-index: 100000000;">
    <ng-container *ngIf="showLoader()">
        <p-progressBar mode="indeterminate" [style]="{'height': '2px', 'width': '100%', 'border-radius': '0px'}" color="secondary"></p-progressBar>
    </ng-container>

</div>
<div class="container-fluid">
    <div class="row">
        <!-- uncomment again -->
        <!-- <p-dialog header="Liability Waiver" [modal]="true" [(visible)]="visible" [style]="{ width: '50vw', height: '700px' }">
            <p>
                I, [Subscriber’s Full Name], hereby acknowledge and agree to the following terms and conditions as a subscriber of myLife A.D., an end-of-life planning and remembrance platform (“myLife A.D.”). By signing this liability waiver, I understand and agree that myLife A.D. shall be relieved of any liability associated with providing access to my account to the Trustee designated by me, as explained in the terms and conditions below:
            </p>

            <ol>
                <li>
                    1. <strong>Designation of Trustee:</strong> As a subscriber, I have the ability to designate a Trustee who will be granted access to my account and the information stored therein after my passing. I understand that it is my responsibility to carefully choose and designate a Trustee who I trust to act in my best interests.
                </li>
                <li>
                    2. <strong>Transfer Process:</strong> In order for the Trustee to gain access to my account, they will be required to initiate the transfer process with myLife A.D. This process may involve providing a death certificate or bypass code that I have created and shared with the Trustee. I understand that myLife A.D. will rely on the accuracy and authenticity of the provided information and will act in good faith based on such information.

                </li>
                <li>
                    3. <strong>Release of Liability:</strong> I fully understand and acknowledge that myLife A.D. is acting solely as a facilitator and is not responsible for any actions or omissions of the Trustee after they have gained access to my account. I hereby release myLife A.D., its officers, directors, employees, agents, successors, and assigns from any and all claims, demands, causes of action, liabilities, costs, and expenses, whether known of unknown, arising out of or in any way connected with the actions or omissions of the Trustee.

                </li>
                <li>
                    4. <strong>Privacy and Security:</strong> At myLife A.D., we take the privacy and security of your information seriously. While we strive to maintain the confidentiality of your account and the data stored within, it is important to understand that no system is completely immune to potential risks. Therefore, in the event of any unauthorized access or disclosure of your account due to unforeseen security breaches or hacking incidents, I acknowledge that myLife A.D. cannot be held liable.

                </li>
                <li>
                    5. <strong>No Guarantees or Promises:</strong> We want to assure you that myLife A.D. is committed to providing a reliable and efficient platform. While we make every effort to ensure the accuracy, completeness, and reliability of the information stored in your
                    account, we cannot guarantee its accuracy or assume liability for any discrepancies or errors that may arise.
                </li>
                <li>
                    6. <strong>Indemnification:</strong> I agree to indemnify, defend, and hold harmless myLife A.D., its officers, directors, employees, agents, successors, and assigns from and against any and all claims, damages, liabilities, costs, and expenses, including reasonable attorneys&rsquo; fees, arising out of or in connection with any breach of this liability waiver or any actions or omissions of the Trustee.

                </li>
            </ol>
            <p>
                <p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary"></p-checkbox>
                I have thoroughly perused the liability waiver and comprehended its entirety. I willingly consent, with the intention of being legally obligated by this agreement, and I affirm that I am of the minimum age of 18 years.
            </p>
            <ng-template pTemplate="footer">
                <div class="px-4 text-center" dg-footer>
                    <div class="d-flex flex-row-reverse">
                        <div class="pl-2 pb-1">
                            <app-icon-button [cssClass]="'btnw215 btn btn-primary'" [isButton]="true" [size]="2" [showTitle]="true" title="I Agree"></app-icon-button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dialog> -->
        <app-dialog #confirmationdg [zIndex]="11000" [hideCloseButton]="true" [isBusy]="false" [xsize]="4" [ysize]="4" [isLoading]="false" [isAnonymous]="true">
            <div class="text-center text-strong my-2" [innerHTML]="getConfirmMessage()"></div>
            <div class="px-4 pb-3 text-center" dg-footer>
                <div class="mb-4 pb-2 d-flex flex-row-reverse">
                    <div class="pl-2 pb-1" *ngFor="let option of getConfirmOptions()">
                        <app-icon-button [cssClass]="option.title == 'I Agree' || option.title == 'Refresh' ? 'btnw215 btn btn-primary' : 'btnw215 btn btn-outline-primary'" [isButton]="true" [size]="2" [showTitle]="true" [title]="option.title" (clicked)="confirmAction(option)"></app-icon-button>
                    </div>
                </div>

                <!-- <button *ngFor="let option of getConfirmOptions()" class="btn btn-primary mr-1" (click)="confirmAction(option)">{{option.title}}</button> -->
            </div>
        </app-dialog>

        <app-dialog (closed)="oncloseddg($event)" #walkthroughdg [isLoading]="isWKDgLoading()" [xsize]="4" [ysize]="4" title="Video">
            <div class="row" *ngIf="!isWKDgLoading()">
                <video id="video" width="100%" controls="controls" preload="metadata" autoplay>
                    <source src="{{videoUrl}}" type="video/mp4">
                </video>
            </div>
            <div dg-footer>
            </div>
        </app-dialog>

        <!-- <app-dialog #dialogue [isLoading]="false" [isBusy]="isfileUpload" [xsize]="3" [ysize]="3" title="Uplaod Death Certificate">
            <div class="container bg-white">
                <div *ngIf="data?.ReturnCode =='Account_Transfer_AlreadyStarted'" class="row">
                    <img class="mster_wdth" [src]="data.Data">
                </div>
                <div *ngIf="data?.ReturnCode !='Account_Transfer_AlreadyStarted'" class="row d-flex justify-content-center">
                    <div class="col-md-12 text-center d-flex flex-column py-4">
                        <div class="col-12 text-center p-0 mster_brdius">
                            <ng-container *ngIf="fileuploadres.length == 0">
                                <i class="pt-3 fal fa-cloud-upload fa-5x mster_colorg"></i>
                                <p class="p-0 mster_colorg">Upload File</p>
                            </ng-container>
                            <ng-container *ngFor="let item of fileuploadres">
                                <div class="col-12 pb-3">
                                    <div class="uploaded">
                                        <i [ngClass]='item.file.name|replceicon' class="mr-2 mster_fsize4"></i>
                                        <div class="file">
                                            <div class="file_name">
                                                <div class="col-6 pl-0 text-truncate mster_w0">
                                                    <h5 class="small text-truncate">{{item.file.name}}</h5>
                                                </div>
                                                <div class="col-4">
                                                    <p>{{item.Load|mbpipe}}</p>
                                                </div>
                                                <div class="col-2 text-right pr-0">
                                                    <i class="fas fa-times" (click)="removeFile(item)"></i>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar bg-info" role="progressbar" [ngStyle]="{'width': item.Percantage + '%'}" aria-valuenow="12" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="pt-3 pb-0 mster_brdiusbg">
                                <div class="d-flex justify-content-center">
                                    <app-s3-file-upload isentitySidebarshow="false" isDeathCertificate="true" [isBtnVisible]="isbtnvisible" (success)="OnPicUpload($event)" [acceptfiletypes]="s3filetypesaccept">
                                    </app-s3-file-upload>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div dg-footer *ngIf="deceased.deathCertificate">
                <app-icon-button title="Upload" [showTitle]="true" iconClass="far fa-check-circle text-primary" [size]="2" (clicked)="Save()">
                </app-icon-button>
            </div>
        </app-dialog> -->
        <!-- <p-toast position="bottom-center" baseZIndex="900000"></p-toast> -->
        <p-toast position="top-right" baseZIndex="900000">
            <ng-template let-message pTemplate="message">
                <div class="flex flex-column" style="flex: 1">
                    <h6>{{message.summary}}</h6>
                    <p [innerHTML]="message.detail"></p>
                </div>
            </ng-template>
        </p-toast>
        <div *ngIf="isLogin() && pageservice.getLeftNavSize() > 0" class="col-12 page-padding d-flex justify-content-between topbar-sticky" style="background:white;">
            <img height="50" style="margin-top: 7px;position: fixed;top: 0;right: calc(100% - 60px);z-index: 10;" [src]="getPortalImage()">
            <ng-container *ngIf="isAdminPortal">
                <div style="position: relative;left: 84px; z-index: 7 !important;" class="my-auto p-0 mr-2 float-left" *ngIf="isMenuFloating()">
                    <app-icon-button iconClass="text-black far fa-bars" (clicked)="openMainNav()" [size]="2"></app-icon-button>
                </div>
            </ng-container>
            <ng-container *ngIf="!isAdminPortal">
                <div [hidden]="isToggleHidden() || (!pageservice.GetUserPurchases() && !pageservice.IsAdminUser())" style="position: relative;left: 84px; z-index: 7 !important;" class="my-auto p-0 mr-2 float-left" *ngIf="isMenuFloating()">
                    <app-icon-button iconClass="text-black far fa-bars" (clicked)="openMainNav()" [size]="2"></app-icon-button>
                </div>
            </ng-container>
            <!-- <ng-container [ngClass]="this.pageservice.getMainContentWidth() <= 1200 ? 'titleentityxs' : 'titleentity'"> -->
            <div id="entitytitle" class="pt-1 mt-2" [ngClass]="!isToggleHidden()  ? 'titleentity'  : 'titleentityxs'" *ngIf="showTitle()">
                <div style="position: absolute;top: 1px;right:-60px;z-index: 10;cursor: pointer;" *ngIf="pageservice.getModuleId() != 14"><img (click)="onWalkThrougthVideo()" *ngIf="pageservice.isWalkThroughButton()" src="assets/images/howtobtn.png"></div>
                <span class="font-weight-bold mster_fsize1" [innerHTML]="getTitle()"></span>
                <!-- <span style="font-size: 1.4rem; font-weight: bold;" class="pl-5">{{data?.Message}}</span>
                    <app-icon-button iconClass="text-black fal fa-check-circle" title="Start Transfer" [showTitle]="true" (clicked)="onStartTransfer()" [size]="2"></app-icon-button> -->

            </div>
            <div class="p-0 ml-auto w-25">

                <a href="#" id="menu1" data-toggle="dropdown" aria-expanded="true">
                    <img height="50" pInputText pTooltip="Manage Profile" tooltipPosition="left" width="50" class="ml-auto rounded-circle profile-picture contnt_pfixd" [src]="getLoginUserImage()">

                    <div class="col-md-3 col-sm-4 dropdown-menu" role="menu" aria-labelledby="menu1">
                        <div class="card border-0">
                            <div class="card-body text-center">
                                <div><img width="65px" height="65px" class="ml-auto rounded-circle profile-picture" [src]="getLoginUserImage()"></div>
                                <h6 class="card-title m-0 pt-3">{{getDisplayLoginNames()}}</h6>
                                <p class="small card-text">{{getLoginUsergGender()}} {{getLoginUserAge()}}</p>
                                <app-icon-button *ngIf="!pageservice.IsAdminUser() && pageservice.GetUserPurchases() && !ismainadmin" cssClass="btn btn-outline-secondary " [showTitle]="true" [size]="2" (clicked)="onProfile()" title="Manage Profile"></app-icon-button>
                            </div>
                            <ng-container *ngIf="getListOfUserProfile()?.length != undefined && pageservice.isLogin() && !ismainadmin">
                                <div class="card-body" [ngClass]="getListOfUserProfile().length > 1 && getListOfUserProfile().length != undefined? 'contnt_p15' : ''">
                                    <app-user-selector [items]="getListOfUserProfile()" isDropdown=false></app-user-selector>
                                </div>
                            </ng-container>
                            <hr style="margin-top: 0px !important;">
                            <div class="text-center pt-1 pb-2">
                                <app-icon-button iconClass="fal fa-sign-out text-black" [showTitle]="true" [size]="2" (clicked)="logout()" title="Signout"></app-icon-button>
                            </div>
                        </div>
                    </div>



                    <!-- <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                        <li role="presentation">
                            <a role="menuitem" tabindex="-1" href="#">HTML</a>
                            <div class="col-md-12 text-center img bg-wrap text-center py-2">
                                <div class="user-logo">
                                    <div class="img">
                                        <img width="100px" height="100px" class="ml-auto rounded-circle profile-picture" [src]="getLoginUserImage()">
                                        <h5 class="pt-2 blockquote display-name">{{getDisplayLoginNames()}}</h5>
                                        <h6 class="display-gender">{{getLoginUsergGender()}} {{getLoginUserAge()}}</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li role="presentation" style="height: 35px;outline: 0;padding: 12px 33px 13px;" *ngFor="let item of getListOfUserProfile()">
                            <span class="truncate-text text-sm text-strong ml-2">{{item.useremail}}</span>
                            <span class="truncate-text d-block text-xsm ml-2">{{item.accounttype}}</span>
                        </li>
                        <hr>
                        <li role="presentation"><a role="menuitem" tabindex="-1" href="#">JavaScript</a></li>
                        <li role="presentation" class="divider"></li>
                        <li role="presentation"><a role="menuitem" tabindex="-1" href="#">About Us</a></li>
                    </ul> -->
                </a>






                <!-- <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                    <img src="dist/img/user2-160x160.jpg" class="user-image" alt="User Image">
                    <span class="hidden-xs">Alexander Pierce</span>
                </a> -->
            </div>
            <!-- </ng-container> -->

            <!-- <div *ngIf="eventdata?.data.AdminStatus == 'active' && eventdata?.data.isAdmin && pageservice.isPageFree() && pageservice.isPageReady()" class="px-3 py-1 text-white rounded border-light" style="background-color: #51a351; position: fixed; top: 0px;left: calc(40vw - 150px);right: calc(40vw - 150px);text-align: center ; z-index: 9999999;">
                <app-icon-button *ngIf="data?.ReturnCode !='Account_Transfer_AlreadyStarted' && data?.ReturnCode !='Account_Transfer_Started'" iconClass="text-white fal fa-check" title="Start Transfer" [showTitle]="false" (clicked)="onStartTransfer()" [size]="2"></app-icon-button>
                <span style="font-size: 1.4rem; font-weight: bold;" class="pl-2 text-white">{{data?.Message}}</span>
            </div> -->
            <!-- <div class="pt-1 mt-2" *ngIf="eventdata?.data.AdminStatus == 'active' && eventdata?.data.isAdmin">
                <span style="font-size: 1.4rem; font-weight: bold;" class="pl-5">{{data?.Message}}</span>
                <app-icon-button iconClass="text-black fal fa-check-circle" title="Start Transfer" [showTitle]="true" (clicked)="onStartTransfer()" [size]="2"></app-icon-button>
            </div> -->
            <div class="d-flex pt-5 mt-3">
                <div id="mainnav_dom" style="z-index: 99999;" class="ontop ui-sidebar ui-sidebar-left ui-sidebar-active mster_ptopmtop" (wheel)="onWheel($event)" [hidden]="!isMenuFloating()">
                    <!-- <div class="my-auto p-0 mr-2 float-left" *ngIf="isMenuFloating() || !isMenuFloating()">
                        <app-icon-button style="float: right;margin: 15px -348px 0px 0px;" iconClass="text-black far fa-bars" (clicked)="openMainNav()" [size]="2"></app-icon-button>
                    </div> -->
                    <!-- <div class="pt-4 pb-2" *ngIf="isMenuFloating()"> -->
                    <!-- <img height="auto" width="auto" [src]="getPortalImageAD()"> -->
                    <!-- <div class="col-md-12 text-center img bg-wrap text-center py-2">
                            <div class="user-logo">
                                <div class="img">
                                    <img width="150px" height="150px" class="ml-auto rounded-circle profile-picture" [src]="getLoginUserImage()">
                                    <h5 class="pt-2 blockquote display-name">{{getDisplayLoginNames()}}</h5>
                                    <h6 class="display-gender">{{getLoginUsergGender()}} {{getLoginUserAge()}}</h6>
                                </div>

                            </div> -->
                    <!-- <img src="/assets/images/profile-icon.png" *ngIf="!loaded"> -->
                    <!-- <span class="blockquote">{{getDisplayLoginNames()}}</span><br>
                                        <span class="small">{{getLoginUsergGender()}} {{getLoginUserAge()}}</span> -->
                    <!-- </div>
                        <div class="dummy-height">
                        </div>
                    </div> -->
                    <div class="mster_overflwh">
                        <ng-container *ngFor="let item of navItems">
                            <a *ngIf="item.isvisible" class="d-block mster_h50" (click)="routeSelection(item)" [ngClass]="{'nav-link-item-selected':isNavSelected(item), 'nav-link-item':!isNavSelected(item)}">
                                <!-- <img [src]='item["icon"]' width="25px" height="35px" class="mr-3"> {{item["title"]}} -->
                                <i [ngClass]='item["icon"]' class="nav_icons mr-3"></i> <span class="menu-title">{{item["title"]}}</span>
                                <span *ngIf="item.count != null && item.count > 0" class="badge badgenumber badge-pill badge-primary">{{item["count"]}}</span>
                            </a>
                        </ng-container>
                    </div>
                    <!-- <div class="mt-auto">
                        <div class="col-12 sidebarbottombtn">
                            <app-icon-button iconClass="fal fa-sign-out text-black" [showTitle]="true" [size]="2" (clicked)="logout()" title="Signout"></app-icon-button>
                            <a href="#"><span class="fa-stack fa-lg"><i class="fa fa-dashboard fa-stack-1x"></i></span> Logout</a>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div class="d-flex">
                <img height="50" *ngIf="!isLoginFloating()" style="margin-bottom: 5px;margin-top: 5px; margin-right:15px" [src]="getPortalImageWithLine()"> -->
            <!-- <img height="50" *ngIf="isLoginFloating()" style="margin-bottom: 5px;margin-top: 5px;" [src]="getPortalImage()"> -->
            <!-- <div class="d-flex" *ngIf="!isLoginFloating()">
                    <div style="width: 130px;" class="ml-1 my-auto">
                        <div class="text-xsm">
                            <span class="text-strong" style="line-height: 1rem;">{{getLoginText()}}</span>
                        </div>
                        <div class="text-strong text-white text-truncate" style="line-height: 1.1rem;" *ngFor="let name of getDisplayLoginNames()">{{name}}</div>
                    </div> -->
            <!-- <div class="my-auto ml-3" *ngIf="showActionButton()">
                        <button (click)="onNavSelected.emit(actionButtonCommand())" class="btn btn-sm btn-topbar btn-pad-sm" [innerHtml]="actionButtonText()"></button>
                    </div> -->
            <!-- </div> -->
            <!-- </div> -->

        </div>
        <div class="col-12" id="maincontentdiv_dom" [style.z-index]="getZindex() ? '0' : ''">
            <p-menu #menu [popup]="true" [model]="getMenuItems()" appendTo="body"></p-menu>

            <ng-content select="[position=content]"></ng-content>
        </div>
    </div>
</div>
<div id="navmask_dom" class="ui-sidebar-mask mster_zindx"></div>