import { Injectable } from '@angular/core';
import { HttpService, ApiResponse, ApiResponseGeneric, ApiActionSettings } from 'src/app/core/services/http.service';
import { PageService } from 'src/app/core/services/page.service';
import { SignUpUserRequest, SocialAuthenticationCodeRequest } from '../models/signuprequestmodel';
import { EventService } from 'src/app/core/services/event.service';
import { ContactVerifyRequest } from '../models/contactverifyresuest';
import { ProfileListResponse } from '../models/manageadmin';


@Injectable()
export class UserService {


  constructor(private pageservice: PageService, private http: HttpService, eventservice: EventService, /* private cacheService: CacheService, , private notificationservice: NotificationService*/) {
    eventservice.observable().subscribe(x => {
      if (x.type == "SIGNED_OUT" || x.type == "SIGNING_IN") {
        this.resetService();
      }
    });
  }

  resetService() {
    //this.defaultPatient = undefined;
    //this.defaultProvider = undefined;
  }


  ChangeUserProfile(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/admin/change-profile", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  InviteList(): Promise<ApiResponseGeneric<InviteUser.InviteUser[]>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<InviteUser.InviteUser[]>>((successresolve, failureresolve) => {
      this.http.Get("/auth/invite-list", undefined, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  InviteUser(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/auth/invite-spouse-significant-others", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }
  
  InviteRestriction(): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Get("/auth/invite-restriction", undefined, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  DeleteInvitation(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/auth/delete-invitation", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }
  

  SignupAdmin(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/auth/signup-admin", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  SignIn(request: AuthenticationRequest.Request): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/login", request, undefined, obj).then(res => {
        this.Login(res);
        this.GetListOfProfiles();
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  AdminSignIn(request: AuthenticationRequest.Request): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/admin-login", request, undefined, obj).then(res => {
        this.Login(res);
        this.GetListOfProfiles();
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }



  GetListOfProfiles(): Promise<ApiResponseGeneric<ProfileListResponse.Response>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<ProfileListResponse.Response>>((successresolve, failureresolve) => {
      this.http.Get("/admin/list-profiles", undefined, undefined, obj).then(res => {
        this.UserProfile(res);
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  isEmailAvailable(request: AuthenticationRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/auth/email-available", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  
  isPhoneAvailable(request: AuthenticationRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/auth/phone-available", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  UpdateProfile(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/profile/update-profile", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  Login(res: any, isrouterefresh: boolean = true) {
    res.Data.user.currentlogin = new Date().toLocaleTimeString();
    let obj =
      this.pageservice.tryLogin({ isLogin: true, Data: res.Data }, isrouterefresh);
    let s: ApiActionSettings = new ApiActionSettings();
    s.showFailureMessage = false;
    s.showSuccessMessage = false;

    // res.Data.Notifications.forEach(m => {
    //   this.notificationservice.addMessage(m.type, m.Title, m.Description, m.Param, new Date(m.CreatedDate));
    // });
  }

  UserProfile(res: any) {
    this.pageservice.UserProfiles({ Data: res.Data });
  }

  GetSignupInformation(request: SignUpUserRequest.SignupTokenRequest): Promise<ApiResponseGeneric<any>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.CouponResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/get-signup-token-info", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  GetDiscountToken(request: SignUpUserRequest.DiscountCoupon): Promise<ApiResponseGeneric<AuthenticationResponse.CouponResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.CouponResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/get-applied-token", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  SignUp(request: SignUpUserRequest.Request, byPassLogin: boolean = false): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/signup", request, undefined, obj).then(res => {
        if (!byPassLogin) {
          this.Login(res);

        }
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  ChangeFullToFullSubscription(): Promise<ApiResponseGeneric<any>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<any>>((successresolve, failureresolve) => {
      this.http.Post("/auth/change-full-subscription", undefined, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  CreatePhoneVerificationRequest(request: any): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/resend-verification-sms", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  EmailVerificationRequest(request: any): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/resend-verification-email", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  ForgotPassword(request: any): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/forgot-password", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  DefaultSubscription(): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Get("/payment/default-subscription", null, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  GetRefreshToken(): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/auth/refresh", null, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  GetUserProfile(): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/auth/me", null, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  // CreateSubscription(request: any): Promise<ApiResponse> {
  //   let obj: ApiActionSettings = new ApiActionSettings();
  //   obj.showFailureMessage = false;
  //   obj.showSuccessMessage = false;
  //   return new Promise<ApiResponse>((successresolve, failureresolve) => {
  //     this.http.Post("/payment/subscription-payment", request, undefined, obj).then(res => {
  //       successresolve(res);
  //     }).catch(err => {
  //       failureresolve(err);
  //     });
  //   });
  // }


  CreateSubscription(request: SignUpUserRequest.Request, byPassLogin: boolean = false): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/payment/subscription-payment", request, undefined, obj).then(res => {
        // if (!byPassLogin) {
        //   this.Login(res);
        // }
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  GetFaceBookUserProfile(request: SocialAuthenticationCodeRequest.Request): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/social-user", request, undefined, obj).then(res => {
        this.Login(res);
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  GetGoogleUserProfile(request: SocialAuthenticationCodeRequest.Request): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/social-user", request, undefined, obj).then(res => {
        this.Login(res);
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  GetGoogleSyncContacts(request: SocialAuthenticationCodeRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/auth/sync-contacts", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  SyncContactsBulkUpdate(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/contact/bulk-update", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  VerifyEmail(request: any): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/verify-email", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  VerifyChangeEmail(request: any): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/verify-change-email", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  CheckPaymentRenewal(request: any): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/check-payment-renewal", request, undefined, obj).then(res => {
        this.Login(res, false);
        this.GetListOfProfiles();
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  VerifyPhone(request: any): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/verify-phone", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  ResetPassword(request: any): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/auth/reset-password", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  UpdateUploadProfile(request: any): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.PostMultiPart("/profile/update-profile-picture", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


  VerifyContact(request: ContactVerifyRequest.Request): Promise<ApiResponse> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponse>((successresolve, failureresolve) => {
      this.http.Post("/contact/verify-contact", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  UpdateVerifyUserPhone(request: any): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/profile/change-phone-number", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  UpdateEmail(request: any): Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>> {
    let obj: ApiActionSettings = new ApiActionSettings();
    obj.showFailureMessage = false;
    obj.showSuccessMessage = false;
    return new Promise<ApiResponseGeneric<AuthenticationResponse.AuthUserResponse>>((successresolve, failureresolve) => {
      this.http.Post("/profile/change-email", request, undefined, obj).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }


}


export namespace AuthenticationRequest {
  export interface Request {

    emailAddress: string;

    password: string;
  }
}


export namespace AuthenticationResponse {

  export interface AuthUserResponse {
    access_token: string;
    expires_in: string;
  }

  export interface CouponResponse {
    amount: string;
    discounttype: number;
    icon: string;
    name: string;
    platform: string;
    subscriptiondormenttext: string;
    subscriptionspacetext: string;
    subscriptiontext: string;
    subscriptiontextwithprice: string;
    subscriptiontextwithpriceFull: string;
  }
}

export namespace InviteUser {
  export interface InviteUser {
    created_at: string;
    email: string;
    firstname: string;
    id: number;
    lastname: string;
    mainuserid: number;
    subuserid: number;
    type: number;
    updated_at: string;
    typeref: InviteUserType;
  }

  export interface InviteUserType {
    id: number;
    name: string;
    refcodeid: number;
    type: string;
  }
}


export namespace UpdateResponse {

  export interface UserResponse {

    user: string;
  }
}
