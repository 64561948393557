import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGaurdAdminService } from 'src/app/core/services/auth-gaurd-admin.service';
import { PendingProcessingGuardService } from '../../core/services/pending-processing-guard.service';


const routes: Routes = [
  { path: '', redirectTo: '/auth', pathMatch: 'full' },
  { path: "auth", loadChildren: () => import('./authentication/signin/signin.module').then(m => m.SigninModule) },
  {
    path: "dashboard",
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGaurdAdminService],
    canDeactivate: [PendingProcessingGuardService]
  },


  {
    path: 'companies',
    loadChildren: () => import('./listing/company/company.module').then(m => m.CompanyModule),
    canActivate: [AuthGaurdAdminService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'companies/managecompany/add',
    loadChildren: () => import('./manage/managecompany/managecompany.module').then(m => m.ManagecompanyModule),
    canActivate: [AuthGaurdAdminService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'companies/managecompany/:entityId',
    loadChildren: () => import('./manage/managecompany/managecompany.module').then(m => m.ManagecompanyModule),
    canActivate: [AuthGaurdAdminService],
    canDeactivate: [PendingProcessingGuardService]
  },

  {
    path: 'discounts',
    loadChildren: () => import('./listing/discount/discount.module').then(m => m.DiscountModule),
    canActivate: [AuthGaurdAdminService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'discounts/managediscount/add',
    loadChildren: () => import('./manage/managediscount/managediscount.module').then(m => m.ManagediscountModule),
    canActivate: [AuthGaurdAdminService],
    canDeactivate: [PendingProcessingGuardService]
  },


  {
    path: 'groupdiscounts',
    loadChildren: () => import('./listing/groupdiscounts/groupdiscounts.module').then(m => m.GroupdiscountsModule),
    canActivate: [AuthGaurdAdminService],
    canDeactivate: [PendingProcessingGuardService]
  },
  {
    path: 'groupdiscount/managegroupdiscount/add',
    loadChildren: () => import('./manage/managegroupdiscount/managegroupdiscount.module').then(m => m.ManagegroupdiscountModule),
    canActivate: [AuthGaurdAdminService],
    canDeactivate: [PendingProcessingGuardService]
  },


  {
    path: 'users',
    loadChildren: () => import('./listing/registerd-users/registerd-users.module').then(m => m.RegisterdUsersModule),
    canActivate: [AuthGaurdAdminService],
    canDeactivate: [PendingProcessingGuardService]
  },


  // {
  //   path: 'cancermonth',
  //   loadChildren: () => import('./listing/miscellaneousdiscounts/cancermonth/cancermonth.module').then(m => m.CancermonthModule),
  //   canActivate: [AuthGaurdAdminService],
  //   canDeactivate: [PendingProcessingGuardService]
  // },
  // {
  //   path: 'miscellaneousdiscounts/cancermonthdiscount/add',
  //   loadChildren: () => import('./manage/managemiscellaneousdiscounts/managecancermonth/managecancermonth.module').then(m => m.ManagecancermonthModule),
  //   canActivate: [AuthGaurdAdminService],
  //   canDeactivate: [PendingProcessingGuardService]
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
