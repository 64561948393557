import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-generic-message',
  templateUrl: './generic-message.component.html',
  styleUrls: ['./generic-message.component.scss']
})
export class GenericMessageComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() message: string;

  @Input() title: string;

  @Input() icon: string;

  @Input() isVertical: boolean = false;

  @Input() buttonText: string;

  @Output() clicked = new EventEmitter();

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.icon) {
      if (this.icon.indexOf("text-") < 0) {
        this.icon = this.icon + " text-dark";
      }
    }
  }
}
