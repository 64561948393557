import {Injectable} from '@angular/core';
import {HttpService, ApiResponseGeneric} from 'src/app/core/services/http.service';
import {DashboardResponse} from '../models/dashboardrequestresponse';

@Injectable()
export class DashboardService {
  constructor(private http: HttpService) {
  }

  ResourceUsabeBar(): Promise<ApiResponseGeneric<ResourceUsageBar>> {
    return new Promise<ApiResponseGeneric<ResourceUsageBar>>((successresolve, failureresolve) => {
      this.http.Get("/dashboard/usage-bar", undefined, undefined, undefined).then(res => {
        successresolve(res);
      }).catch(err => {
        failureresolve(err);
      });
    });
  }

  GetDashBoardDetails(isForPaymentPage: boolean = false): Promise<ApiResponseGeneric<DashboardResponse.Response>> {
    return new Promise<ApiResponseGeneric<DashboardResponse.Response>>((successresolve, failureresolve) => {
      if (isForPaymentPage) {
        this.http.Get("/dashboard?isForPaymentPage=" + isForPaymentPage, undefined, undefined, undefined).then(res => {
          successresolve(res);
        }).catch(err => {
          failureresolve(err);
        });
      } else {
        this.http.Get("/dashboard", undefined, undefined, undefined).then(res => {
          successresolve(res);
        }).catch(err => {
          failureresolve(err);
        });
      }
    });
  }
}

export interface ResourceUsageBar {
  totalpurchasespace: number;
  totalconsumedspace: number;
  text: string;
  percentage: number;
}
