import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Helper } from '../../helpers/helper';
import { PageService } from '../../services/page.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @ViewChild('bsdg', { static: false }) bsdg: ElementRef;

  @Input() id: string;

  @Input() hideCloseButton: boolean = false;

  @Input() isLoading: boolean = true;

  @Input() customFooter: boolean = false;

  @Input() title: string = "No Title";

  @Input() isBusy: boolean = false;

  @Input() xsize: number = 2;

  @Input() ysize: number = 2;

  @Input() isAnonymous: boolean = false;

  @Input() showFooter: boolean = true;

  @Input() zIndex: number = 9000;

  @Output() closed = new EventEmitter<boolean>();

  @Output() back = new EventEmitter<boolean>();

  @Input() timeZoneText: string;

  @Input() showHeader: boolean = true;

  @Input() showBackButton: boolean = false;



  constructor(private pageservice: PageService, private route: Router) {
    this.id = Helper.getID();
  }
  ngOnInit() {

  }
  open() {
    $('#' + this.id).modal('show');
  }
  close(reload: boolean = true) {
    $('#' + this.id).modal('hide');
    this.closed.emit(reload);
  }
  onBack(onback: boolean = true) {
    this.back.emit(onback);
  }
  getClass() {

    let str: string;

    if (this.ysize == 1) {
      str = "modal-sm-y";
    }
    else if (this.ysize == 2) {
      str = "modal-md-y";
    }
    else if (this.ysize == 3) {
      str = "modal-lg-y";
    }
    else if (this.ysize == 4) {
      str = "modal-xl-y";
    }
    else {
      str = "modal-xxl-y";
    }

    if (!this.showHeader) {
      return "modal-body-padding " + str;
    } else {
      return str;
    }

  }

  getModalClass() {
    if (this.xsize == 1) {
      return "modal-sm";
    }
    else if (this.xsize == 2) {
      return "modal-md";
    }
    else if (this.xsize == 3) {
      return "modal-lg";
    }
    else if (this.xsize == 4) {
      return "modal-xl";
    }
    else {
      return "modal-xxl";
    }
  }

  NavigateToLogin() {
    return !this.isAnonymous && !this.pageservice.isLogin();
  }

  Route() {
    this.close();
    this.route.navigate(["signin"]);
  }

}
