import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { S3FileUploadComponent } from './s3-file-upload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { IconButtonModule } from 'src/app/core/components/icon-button/icon-button.module';
import { EntitysidebarModule } from '../entity/entitysidebar/entitysidebar.module';



@NgModule({
  declarations: [S3FileUploadComponent],
  imports: [
    CommonModule,
    FileUploadModule,
    IconButtonModule,
    EntitysidebarModule
  ],
  exports: [S3FileUploadComponent]
})
export class S3FileUploadModule { }
