<a *ngIf="isButton"  [ngClass]="cssClass" class="w-100 cursor-hand justify-content-center d-flex align-items-center" (click)="onClick($event)">
    <span [ngStyle]="{'font-size.rem': iconSize, 'line-height.rem': iconSize + 0.1}" class="p-0">
        <i [ngClass]="iconClass"></i>
    </span>
    <span [ngStyle]="{'font-size.rem': textSize, 'line-height.rem': textSize + 0.1}" [ngClass]="textClass" class="ml-1 p-0">
        {{title}}
    </span>
</a>

<div *ngIf="!isButton && inline == true" class="d-inline text-truncate" (click)="onClick($event)" [ngClass]="cssClass">
    <button [disabled]="isDisabled" [ngStyle]="{'font-size.rem': iconSize, 'line-height.rem': iconSize + 0.1}" class="btn p-0">
        <i [ngClass]="iconClass"></i>
    </button>
    <button [disabled]="isDisabled" *ngIf="showTitle" [ngStyle]="{'font-size.rem': textSize, 'line-height.rem': textSize + 0.1}" [ngClass]="textClass" class="btn p-0">
        {{title}}
    </button>
</div>

<div *ngIf="!isButton && inline == false" class="d-inline d-flex cursor-hand my-1" (click)="onClick($event)" [ngClass]="cssClass">
    <span [ngClass]="iconClass" [ngStyle]="{'font-size.rem': iconSize}"></span>
    <span class="align-middle" [ngStyle]="{'font-size.rem': textSize}" class="my-auto ml-1 text-truncate">{{title}}</span>
</div>