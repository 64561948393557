import { BaseView as CoreBaseView } from 'src/app/core/models/baseview';
import { PageService } from 'src/app/core/services/page.service';
import { SharedHelper } from '../helper/sharedhelper';
import { UserService } from '../services/user.service';
import { DateHelper } from 'src/app/core/helpers/datehelper';

export class SharedBaseView extends CoreBaseView {
    constructor(pageservice: PageService) {
        super(pageservice);
    }

    private _userservice: UserService;


    setUserService(userservice: UserService) {
        this._userservice = userservice;
    }

    getUserService() {
        if (this._userservice) {
            return this._userservice;
        }

        this.setPageFault("User information is missing.");
    }

    getCardClass() {
        if (this.getPageService().getModuleName()) {
            return "_" + this.getPageService().getModuleName().toLowerCase().replace(" ", "_");
        }
        else {
            return "_";
        }
    }

    GetKeyValues(data: any): any {
        let temp: any[] = [];
        data.forEach(x => {
            var obj: { [k: string]: any } = {};
            obj.Key = x.uuid,
                obj.Value = x.name;
            temp.push(obj);
        })
        return temp;
    }

    getSharedHelper() {
        return SharedHelper;
    }

    getDateHelper(): any {
        return DateHelper;
    }

    trylogin(request: any, issocial: boolean): Promise<any> {
        return new Promise((success, failure) => {
            request.then(res => {
                success(true);
                // if (!res.Data.renewpaymenturl)
                this.route("dashboard");
                //this.route(environment.homeRoute);
            }).catch(err => {
                failure();
                setTimeout(() => {
                    if (err.IsAppResponse) {
                        if (err.ReturnCode == "Auth_Failure_EmailVerification") {
                            this.routeUnique("auth/verfication", err);
                        }
                        else if (err.ReturnCode == "Auth_Failure_EmailPhoneVerification") {
                            this.routeUnique("auth/verfication", err);
                        }
                        else if (err.ReturnCode == "Auth_Failure_PhoneVerification") {
                            this.routeUnique("auth/verfication", err);
                        }
                        else if (err.ReturnCode == "No_Active_Subscription") {
                            this.route("auth/payment");
                        }
                        else if (err.ReturnCode == "Social_Email_Not_Exists") {
                            this.routeUnique("auth/signup", err);
                        }
                        // else if (err.ReturnCode == "Auth_Failure_Password_Expired") {
                        //     this.showErrorMsg(err.Message, "Password Expired")
                        //     setTimeout(() => {
                        //         this.testroute(err.Data.ResetPasswordUrl);
                        //         // window.open(err.Data.ResetPasswordUrl, "_self");
                        //     }, 1500);
                        // }
                        else {
                            if (err.Message.indexOf('Coupon not valid or already consumed') >= 0) {
                                this.showErrorMsg(err.Message, "Coupon Error")
                            }
                            else if (err.Message.indexOf('Apologies, but we were unable to locate your account within our system') >= 0
                                || err.Message.indexOf('Username and/or password do not match. Please try again.') >= 0) {
                                this.showErrorMsg(err.Message, 'Login Error')
                            }
                            else {
                                this.showErrorMsg(err.Message, "Verification Required")
                            }
                            // this.route(this.signinRoute());
                        }
                    }
                    else {
                        //this.route(this.signinRoute());
                    }
                }, 200);
            });
        });




        /*return new Promise((success, failure) => {
            request.then(res => {
                success();
                if (!Helper.checkIsStringEmpty(this.getPageService().GetRedirectUrl())) {
                    let redirectUrl: string = this.getPageService().GetRedirectUrl();
                    this.getPageService().ClearRedirectUrl();
                    this.route(redirectUrl);
                }
                else {
                    this.route(environment.homeRoute);
                }
            }).catch(err => {
                failure();
                setTimeout(() => {
                    if (err.IsAppResponse) {
                        if (err.ReturnCode == "USER_EMAIL_NOT_VERIFIED") {
                            this.routeUnique("verfication", err);
                        }
                        else if (err.ReturnCode == "USER_PHONE_NOT_VERIFIED") {
                            this.routeUnique("verfication", err);
                        }
                        else if (err.ReturnCode == "USER_EMAIL_PHONE_NOT_VERIFIED") {
                            this.routeUnique("verfication", err);
                        }
                        else if (issocial && err.ReturnCode == "USER_NOT_FOUND" && environment.type == PortalType.Patient) {
                            this.routeUnique("signup", err);
                        }
                        else {
                            err.Messages.forEach(x => this.showErrorMsg(x));
                            this.route(this.signinRoute());
                        }
                    }
                    else {
                        this.route(this.signinRoute());
                    }
                }, 200);
            });
        })*/
    }
}
